<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">POS Customer Search</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i
      class="feather icon-x"></i></button>
</div>
<div class="modal-body ">
  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-label>Enter Search String</mat-label>
        <textarea matInput type="search" placeholder="search here"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row border">
    <div class="col">

    </div>
  </div>

</div>
