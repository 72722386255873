import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerFeedbackComponent } from './customer-feedback/customer-feedback.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { DxDataGridModule } from 'devextreme-angular';
import {MatRadioModule} from '@angular/material/radio';
import { DxCheckBoxModule } from 'devextreme-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatCheckboxModule} from '@angular/material/checkbox';



@NgModule({
  declarations: [
    CustomerFeedbackComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DxDataGridModule,
    MatRadioModule,
    DxCheckBoxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgbModule,
    MatCheckboxModule
  ],
  exports: [
    CustomerFeedbackComponent
  ]
})
export class CustomerModule { }
