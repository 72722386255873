<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">POS Discount Distribute</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i class="feather icon-x" ></i></button>
</div>
<div class="modal-body ">
  <div class="row">
    <div class="col">

      <dx-data-grid #dataGrid id="gridContainer" keyExpr="ID" [showBorders]="true" >
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-editing mode="cell" [allowUpdating]="true">
        </dxo-editing>
        <dxo-selection mode="multiple"></dxo-selection>
        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <dxi-column *ngFor="let data of columnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
          [allowEditing]="true" alignment="center" backgroundcolor="#000" >
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>

  <div class="footer-btn">
    <button class="btn btn-primary">Apply</button>&nbsp;
    <button class="btn btn-danger" (click)="close()">Close</button>
  </div>
</div>
