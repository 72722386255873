<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">POSDayBook</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i
      class="feather icon-x"></i></button>
</div>
<div class="modal-body daybook">
  <div class="container">

    <div class="row">

      <div class="col-3">
        <mat-form-field>
          <mat-label>Branch</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field>
          <mat-label>Puchase Date From</mat-label>
          <input matInput [matDatepicker]="datefrom">
          <mat-datepicker-toggle matSuffix [for]="datefrom"></mat-datepicker-toggle>
          <mat-datepicker #datefrom></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field>
          <mat-label>Puchase Date To</mat-label>
          <input matInput [matDatepicker]="dateto">
          <mat-datepicker-toggle matSuffix [for]="dateto"></mat-datepicker-toggle>
          <mat-datepicker #dateto></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-3">
        <button class="ribbon-button">
          <span class="icon">
            <i class="fa-solid fa-rotate"></i>
          </span>
          <span class="caption">Refresh</span>
        </button>
        <button class="ribbon-button">
          <span class="icon">
            <i class="fa fa-print"></i>
          </span>
          <span class="caption">Print</span>
        </button>
        <button class="ribbon-button" (click)="close()">
          <span class="icon">
            <i class="fa fa-x" style="color: red;"></i>
          </span>
          <span class="caption">Exit</span>
        </button>
      </div>
    </div>

    <mat-tab-group>
      <mat-tab label="Pos Register">
        <dx-data-grid
          id="gridContainer"
          [showBorders]="true">
          <dxo-paging [enabled]="false"></dxo-paging>

          <dxo-selection mode="single"></dxo-selection>
          <dxi-column *ngFor="let data of columnhead"
            dataField="{{data}}" [width]="auto" caption="{{data}}"
            [allowEditing]="true" alignment="center"
            backgroundcolor="#000">
          </dxi-column>

        </dx-data-grid>
      </mat-tab>
      <mat-tab label="Pos Purchase & Old Gold Purchase">
        <div class="row">
          <div class="col-4">
            <div class="title">
              Net Collection
            </div>
            <dx-data-grid
              id="gridContainer"
              [showBorders]="true">
              <dxo-paging [enabled]="false"></dxo-paging>

              <dxo-selection mode="single"></dxo-selection>
              <dxi-column *ngFor="let data of netcollectionhead"
                dataField="{{data}}" [width]="auto" caption="{{data}}"
                [allowEditing]="true" alignment="center"
                backgroundcolor="#000">
              </dxi-column>

            </dx-data-grid>
          </div>
          <div class="col-4">
            <div class="title">
             Old Gold Purchase Summary
            </div>
            <dx-data-grid
              id="gridContainer"
              [showBorders]="true">
              <dxo-paging [enabled]="false"></dxo-paging>

              <dxo-selection mode="single"></dxo-selection>
              <dxi-column *ngFor="let data of oldgoldhead"
                dataField="{{data}}" [width]="auto" caption="{{data}}"
                [allowEditing]="true" alignment="center"
                backgroundcolor="#000">
              </dxi-column>

            </dx-data-grid>
          </div><div class="col-4">
            <div class="title">
              POS Account Balance
            </div>
            <dx-data-grid
              id="gridContainer"
              [showBorders]="true">
              <dxo-paging [enabled]="false"></dxo-paging>

              <dxo-selection mode="single"></dxo-selection>
              <dxi-column *ngFor="let data of posaccounthead"
                dataField="{{data}}" [width]="auto" caption="{{data}}"
                [allowEditing]="true" alignment="center"
                backgroundcolor="#000">
              </dxi-column>

            </dx-data-grid>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Movements">
        <div class="row">
          <div class="col-6">
            <div class="title">
              POS Account Movement
            </div>
            <dx-data-grid
              id="gridContainer"
              [showBorders]="true">
              <dxo-paging [enabled]="false"></dxo-paging>

              <dxo-selection mode="single"></dxo-selection>
              <dxi-column *ngFor="let data of posaccountmovementhead"
                dataField="{{data}}" [width]="auto" caption="{{data}}"
                [allowEditing]="true" alignment="center"
                backgroundcolor="#000">
              </dxi-column>

            </dx-data-grid>
          </div>
          <div class="col-6">
            <div class="title">
              POS Salesorder Order Summary
            </div>
            <dx-data-grid
              id="gridContainer"
              [showBorders]="true">
              <dxo-paging [enabled]="false"></dxo-paging>

              <dxo-selection mode="single"></dxo-selection>
              <dxi-column *ngFor="let data of possalesordersummaryhead"
                dataField="{{data}}" [width]="auto" caption="{{data}}"
                [allowEditing]="true" alignment="center"
                backgroundcolor="#000">
              </dxi-column>

            </dx-data-grid>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Others">
        <div class="row">
          <div class="col-6">
            <div class="title">
             Salesman Wise Summary
            </div>
            <dx-data-grid
              id="gridContainer"
              [showBorders]="true"
              class="minheight"
              >
              <dxo-paging [enabled]="false" ></dxo-paging>

              <dxo-selection mode="single"></dxo-selection>
              <dxi-column *ngFor="let data of salesmanwisesummaryhead"
                dataField="{{data}}" [width]="auto" caption="{{data}}"
                [allowEditing]="true" alignment="center"
                backgroundcolor="#000">
              </dxi-column>

            </dx-data-grid>
          </div>
          <div class="col-6">
            <div class="title">
              Cash & Creadit Card Receipt Summary
            </div>
            <dx-data-grid
              id="gridContainer"
              [showBorders]="true">
              <dxo-paging [enabled]="false"></dxo-paging>

              <dxo-selection mode="single"></dxo-selection>
              <dxi-column *ngFor="let data of creditcashhead"
                dataField="{{data}}" [width]="auto" caption="{{data}}"
                [allowEditing]="true" alignment="center"
                backgroundcolor="#000">
              </dxi-column>

            </dx-data-grid>
            <div class="row">
              <div class="col-7">
                <div class="subtitle">
                  Sales Purchase Details
                </div>
                <mat-form-field>
                  <mat-label>Qty</mat-label>
                  <input type="input" matInput>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Amount</mat-label>
                  <input type="input" matInput>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>POS Rcpt Total</mat-label>
                  <input type="input" matInput>
                </mat-form-field>
              </div>
              <div class="col-5">
                <div class="subtitle">
                  Chq.Rcvd
                </div>
                <mat-form-field>
                  <mat-label>Payment</mat-label>
                  <input type="input" matInput>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Scp. Pur. Amt</mat-label>
                  <input type="input" matInput>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Net Total</mat-label>
                  <input type="input" matInput>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

  </div>


</div>
