import { PosComplaintComponent } from './../../pos-forms/pos-complaint/pos-complaint.component';
import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OrderLockUnlockComponent } from '../../order/order-lock-unlock/order-lock-unlock.component';
import { PosActionComponent } from '../../pos-forms/pos-action/pos-action.component';
import { PendingSalesOrderComponent } from '../../pos-forms/pending-sales-order/pending-sales-order.component';
import { CustomerFeedbackComponent } from '../../customers/customer-feedback/customer-feedback.component';
import {CurrencyReceiptRegisterComponent} from '../../pos-forms/currency-receipt-register/currency-receipt-register.component';
import { ConsigmentStockComponent } from '../../consigment/consigment-stock/consigment-stock.component';
import { DiscountAuthorizationComponent } from '../../consigment/discount-authorization/discount-authorization.component';
import { PosDiscountDistributeComponent } from '../../pos-forms/pos-discount-distribute/pos-discount-distribute.component';
import { PosCustomerSearchComponent } from '../../pos-forms/pos-customer-search/pos-customer-search.component';
import { PosPcrSelectionComponent } from '../../pos-forms/pos-pcr-selection/pos-pcr-selection.component';
import { PosFeedbackComponent } from '../../pos-forms/pos-feedback/pos-feedback.component';
import { PosSalesCommissionComponent } from '../../pos-forms/pos-sales-commission/pos-sales-commission.component';
import { PosItemSplitComponent } from '../../pos-forms/pos-item-split/pos-item-split.component';
import { PosSalesmanCommissionListComponent } from '../../pos-forms/pos-salesman-commission-list/pos-salesman-commission-list.component';
import { PosSchemeMasterComponent } from '../../pos-forms/pos-scheme-master/pos-scheme-master.component';
import { PosWalkinCustomerComponent } from '../../pos-forms/pos-walkin-customer/pos-walkin-customer.component';
import { PosNetSalesSummaryComponent } from '../../pos-forms/pos-net-sales-summary/pos-net-sales-summary.component';
import { ConsignmentItemDetailComponent } from '../../consigment/consignment-item-detail/consignment-item-detail.component';
import { EstimationListComponent } from '../../consigment/estimation-list/estimation-list.component';
import { DayBookComponent } from '../../pos-forms/day-book/day-book.component';
import { OrderCancellationComponent } from '../../order/order-cancellation/order-cancellation.component';
import { SchemeRegistrationComponent } from '../../order/scheme-registration/scheme-registration.component';
import { SchemeRegistrationDetailsComponent } from '../../order/scheme-registration-details/scheme-registration-details.component';
import { PosSalesDiaDetailGstComponent } from '../../pos-forms/pos-sales-dia-detail-gst/pos-sales-dia-detail-gst.component';
@Component({
  selector: 'app-demo1',
  templateUrl: './demo1.component.html',
  styleUrls: ['./demo1.component.scss']
})
export class Demo1Component implements OnInit {
  modalReference:any;
  closeResult:any;
  pageTitle: any;

  constructor(
    private modalService: NgbModal,
  ){}

  ngOnInit(): void {
  }

  openmodal(content) {

    this.modalReference = this.modalService.open(content, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      windowClass : 'tab-full',
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

     }
   private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openestimationlist()
  {
    this.modalService.open(EstimationListComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'currency-receipt-register-action'
    });
  }


  openconsignmentitemdetail()
  {
    this.modalService.open(ConsignmentItemDetailComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'currency-receipt-register-action'
    });
  }

  openordercancel()
  {
    this.modalService.open(OrderCancellationComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'currency-receipt-register-action'
    });
  }



  opendiscountauth()
  {
    this.modalService.open(DiscountAuthorizationComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'currency-receipt-register-action'
    });
  }

  openconsignmentstock()
  {
    this.modalService.open(ConsigmentStockComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'currency-receipt-register-action'
    });
  }

  openlockunlock() {
    this.modalService.open(OrderLockUnlockComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'order-lock-unlock'
    });
  }

  openschemeregisteration() {
    this.modalService.open(SchemeRegistrationComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'order-lock-unlock'
    });
  }

  openschemeregisterdetail() {
    this.modalService.open(SchemeRegistrationDetailsComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'order-lock-unlock'
    });
  }


  openposaction(){
    this.modalService.open(PosActionComponent, {
      size: 'md',
      backdrop: true,
      keyboard: false,
      windowClass: 'pos-action'
    });
  }
  openPosComplaint(){
    this.modalService.open(PosComplaintComponent, {
      size: 'md',
      backdrop: true,
      keyboard: false,
      windowClass: 'pos-complaint'
    });
  }
  openpendingsalesorder(){
    this.modalService.open(PendingSalesOrderComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'pending-sales-order'
    });
  }
  opencustomerfeedback(){
    this.modalService.open(CustomerFeedbackComponent, {
      size: 'xl',
      backdrop: true,
      keyboard: false,
      windowClass: 'customer-feedback'
    });
  }
  opencurrencyreceiptregister()
  {
    this.modalService.open(CurrencyReceiptRegisterComponent, {
      size: 'md',
      backdrop: true,
      keyboard: false,
      windowClass: 'currency-receipt-register-action'
    });
  }
  opendiscountdistribute()
  {
    this.modalService.open(PosDiscountDistributeComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'discount-distribut'
    });
  }
  opencustomersearch()
  {

    this.modalService.open(PosCustomerSearchComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'customer-search'
    });
  }
  openpcrselection()
  {

    this.modalService.open(PosPcrSelectionComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'customer-search'
    });
  }
  openposfeedback()
  {

    this.modalService.open(PosFeedbackComponent, {
      size: 'md',
      backdrop: true,
      keyboard: false,
      windowClass: 'customer-search'
    });
  }
  openpossalesmancommission()
  {

    this.modalService.open(PosSalesCommissionComponent, {
      size: 'md',
      backdrop: true,
      keyboard: false,
      windowClass: 'customer-search'
    });
  }
  openpositemsplit()  {

    this.modalService.open(PosItemSplitComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'customer-search'
    });
  }
  openpossalescommissionlist()  {

    this.modalService.open(PosSalesmanCommissionListComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'customer-search'
    });
  }

  openschememaster()  {

    this.modalService.open(PosSchemeMasterComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'customer-search'
    });
  }

  opencustomerwalkin()  {

    this.modalService.open(PosWalkinCustomerComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'customer-search'
    });
  }

  opennetsalessummary()  {

    this.modalService.open(PosNetSalesSummaryComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'customer-search'
    });
  }
  opendaybook(){
    this.modalService.open(DayBookComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'day-book'
    });
  }
  openpossalesdetail(){
    this.modalService.open(PosSalesDiaDetailGstComponent, {
      size: 'xl',
      backdrop: true,
      keyboard: false,
      windowClass: 'day-book'
    });
  }
}
