<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">Estimation List</h5>
  <button type="button" class="btn btn-close" style="border-color: none;" (click)="close()"><i class="feather icon-x"
      style="font-size: medium;color:
      #FFFFFF;"></i></button>
</div>

<div class="modal-body orderlockunlock">
  <div class="container">

    <dx-data-grid id="gridContainer" [dataSource]="bills" keyExpr="Estimation Date"
      [columns]="['Estimation Date','Estimation No', 'Sales Man Code', 'Customer Code', 'Custome Mobile', 'Customer Name', 'Amount']"
      [showBorders]="true">
      <dxo-selection mode="multiple"></dxo-selection>
    </dx-data-grid>
  </div>
  <div class="row" style="padding-bottom: 10px; float:right; padding-top: 5px;">
    <div class="col-12">
      <button class="btn btn-primary" >Apply</button>&nbsp;
      <button class="btn btn-danger" (click)="close()">Close</button>&nbsp;
    </div>


  </div>
</div>
