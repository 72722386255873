import { CommonfuncService } from './../../../../../commonfunc.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatInput } from '@angular/material/input';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, Renderer2, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuntechapiService } from 'src/app/suntechapi.service';

@Component({
  selector: 'app-pos-sales-dia-detail',
  templateUrl: './pos-sales-dia-detail-gst.component.html',
  styleUrls: ['./pos-sales-dia-detail-gst.component.scss']
})
export class PosSalesDiaDetailGstComponent implements OnInit {
  @ViewChild('txtItemCodeEle', { static: false }) txtItemCodeEle: MatInput;
  @ViewChild('txtDivisionEle', { static: false }) txtDivisionEle: MatInput;
  @ViewChild('txtMGrssWtEle', { static: false }) txtMGrssWtEle: MatInput;

  @Input() public currency;
  @Input() public txtCurRate;


  // Data Hash
  li_item_code_data_hash: any = " ";
  txtColor_data_hash: any;
  txtSize_data_hash: any;
  txtDesign_data_hash: any;

  lineItemForm: FormGroup;

  divisionMS: any = 'M';
  bolBackSpace: boolean = false;
  strSETRef: any;
  designColSiz: any;
  curr_line_item_images: any;
  divisionMSCostDetails: any;
  stockCodeValidationResult: any;

  stockCost: number = 0.00;
  stockFCCost: number = 0.00;
  dblPrice1Val: number = 0.00;
  dblPrice2Val: number = 0.00;
  dblSalesStonePrice1: number = 0.00;
  defPriceMPOS: number = 0;
  defPriceMPOSStn: number = 0;
  dblPrice1Per: number = 0;
  dblPrice2Per: number = 0;

  blnPOSFixed: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private comFunc: CommonfuncService,
    private renderer: Renderer2,
    private suntechApi: SuntechapiService,

  ) {

    this.lineItemForm = this.formBuilder.group({
      txtItemCode: ['', Validators.required],
      txtItemDescription: ['', Validators.required],
      txtDivision: ['', Validators.required],
      txtLocation: ['', Validators.required],
      txtMPcs: ['', Validators.required],
      txtMStoneWt: ['', Validators.required],
      txtMNetWt: ['', Validators.required],
      // fcn_li_rate: ['', Validators.required],
      // fcn_li_total_amount: ['', Validators.required],
      // fcn_li_discount_percentage: ['', Validators.required],
      // fcn_li_discount_amount: ['', Validators.required],
      // fcn_li_gross_amount: ['', Validators.required],
      // fcn_li_tax_percentage: ['', Validators.required],
      // fcn_li_tax_amount: ['', Validators.required],
      // fcn_li_net_amount: ['', Validators.required],

      txtMGrssWt: ['', Validators.required],
      txtDesign: ['', Validators.required],
      txtColor: ['', Validators.required],
      txtSize: ['', Validators.required],
      txtIGSTPer: ['', Validators.required],
      txtIGSTAmt: ['', Validators.required],
      txtIGSTAmtDia: ['', Validators.required],
      txtIGSTPerDia: ['', Validators.required],

      txtSupplier: ['', Validators.required],
      txtSalesPersonCode: ['', Validators.required],
      txtMainStockCode: ['', Validators.required],
      cmbMcUnit: ['', Validators.required],
      txtMChargable: ['', Validators.required],

      // 1st row in metal
      txtMakingValueWt: ['', Validators.required],
      txtMMkgRate: ['', Validators.required],
      txtMMkgAmt: ['', Validators.required],
      txtMdiscPerc: ['', Validators.required],
      txtMDiscAmt: ['', Validators.required],
      txtMMakingNetAmount: ['', Validators.required],

      // 2nd row in metal
      txtMetalValueWt: ['', Validators.required],
      txtMMetalRate: ['', Validators.required],
      txtMMetalAmt: ['', Validators.required],
      txtMMetalNetAmount: ['', Validators.required],

      // 3rd row in metal
      txtStoneValueWt: ['', Validators.required],
      txtMStoneRate: ['', Validators.required],
      txtMStoneAmt: ['', Validators.required],
      txtMStoneNetAmount: ['', Validators.required],

      // 4th row in metal
      txtStampValueWt: ['', Validators.required],
      txtStampRate: ['', Validators.required],
      txtStampAmount: ['', Validators.required],
      txtMStampNetAmount: ['', Validators.required],


      txtMTotAmt: ['', Validators.required],


    });


  }

  ngOnInit(): void {
  }
  close() {
    this.activeModal.close();
  }
  txtItemCode_KeyDown(event) {
    console.log(event.key);
    if (event.key === "Enter") {
      if (this.lineItemForm.value.txtItemCode.trim() != '') {
        this.li_item_code_data_hash = this.lineItemForm.value.txtItemCode.trim()
      } else {
        this.li_item_code_data_hash = '';
      }
    }
    if (event.key === "Backspace") {

      if (this.lineItemForm.value.txtItemCode.trim() == '') {
        this.bolBackSpace = true;
        this.lineItemForm.controls['txtItemDescription'].setValue('');
        this.txtDivisionEle['nativeElement'].focus();
      }
    }
  }
  txtItemCode_Enter() {

    // Item code enter function
    this.txtItemCodeEle['nativeElement'].addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        console.log(this.lineItemForm.value.txtItemCode);
        if (this.lineItemForm.value.txtItemCode.trim() != '') {
          this.li_item_code_data_hash = this.lineItemForm.value.txtItemCode.trim()
        } else {
          this.li_item_code_data_hash = '';
        }
      }
    });
  }

  txtItemCode_Validating(event) {
    this.strSETRef = '';

    if (this.bolBackSpace) {
      this.renderer.selectRootElement('#txtDivisionEle').focus();
      return;
    }

    this.li_item_code_data_hash = ' ';
    if ((this.li_item_code_data_hash.toString() == this.lineItemForm.value.txtItemCode.trim()) && this.lineItemForm.value.txtItemCode.trim() != '') {
      this.txtMGrssWtEle['nativeElement'].focus();
      return;
    }

    const MTLDESIGNWISEIMPORT = this.comFunc.Null2BitValue(this.comFunc.allCompanyParams.MTLDESIGNWISEIMPORT);
    const allMessageBoxData = this.comFunc.allMessageBoxData;

    if (MTLDESIGNWISEIMPORT) {
      this.designColSiz = this.lineItemForm.value.txtItemCode.trim().split('/');
      if (this.designColSiz.length > 4) {
        // MSG2451
        this.snackBar.open('', 'OK');
        this.renderer.selectRootElement('#txtDesign').focus();
        return;
      }
      if (this.designColSiz.length > 3) {
        this.lineItemForm.controls['txtDesign'].setValue(this.designColSiz[2].toString());
        this.lineItemForm.controls['txtColor'].setValue(this.designColSiz[3].toString());
        this.txtColor_Validating(null);
        this.lineItemForm.controls['txtSize'].setValue(this.designColSiz[1].toString());
        this.txtSize_Validating(null);
      }
      if (this.designColSiz.length > 2) {
        // MSG2451
        this.snackBar.open('', 'OK');
        this.lineItemForm.controls['txtDesign'].setValue(this.designColSiz[2].toString());
        this.lineItemForm.controls['txtSize'].setValue(this.designColSiz[1].toString());
        this.txtColor_Validating(null);
        return;
      }
    }


    var strBranchcode = localStorage.getItem('userbranch');
    var strUser = localStorage.getItem('username');
    this.suntechApi
      // .getPOSStockCodeValidation(event.target.value, strBranchcode, strUser)
      .getPOSStockCodeValidationCode(event.target.value)
      .subscribe((resp) => {
        this.bolBackSpace = false;
        if (resp.Status == 'Failed') {
          this.snackBar.open('Invalid Stock Code', 'OK');
        } else {
          console.log(resp.Result);
          const result = resp.Result[0];
          this.stockCodeValidationResult = resp.Result[0];
          console.log(result.DIVISIONMS);

          this.divisionMS = result.DIVISIONMS;
          this.lineItemForm.controls['txtItemDescription'].setValue(result.DESCRIPTION);
          this.lineItemForm.controls['txtDivision'].setValue(result.DIVISION);
          this.lineItemForm.controls['txtMNetWt'].setValue(result.NETWT);
          this.lineItemForm.controls['txtMGrssWt'].setValue(result.GROSSWT);
          this.lineItemForm.controls['txtMStoneWt'].setValue(result.STONEWT);
          this.lineItemForm.controls['txtMPcs'].setValue(result.PCS);
          this.lineItemForm.controls['txtColor'].setValue(result.COLOR);
          this.lineItemForm.controls['txtDesign'].setValue(result.DESIGN_CODE);
          this.lineItemForm.controls['txtSize'].setValue(result.SIZE);
          this.lineItemForm.controls['txtSupplier'].setValue(result.SUPPLIER);

          this.getMetalOrDiaCost(result.DIVISIONMS);
          // this.curr_line_item_images = result.PICTURE_NAME;

        }
      });

    this.suntechApi
      .getPOS_RetailSalesItemImage(event.target.value)
      .subscribe((resp) => {
        if (resp.Status == 'Failed') {
        } else {
          this.curr_line_item_images = resp.Result[0].imagepath;
        }
      });

  }

  clearDesignTextboxOnly() {

    this.lineItemForm.controls['txtDesign'].setValue('');
    this.lineItemForm.controls['txtColor'].setValue('');
    this.lineItemForm.controls['txtSize'].setValue('');
    this.lineItemForm.controls['txtIGSTPer'].setValue('');
    this.lineItemForm.controls['txtIGSTAmt'].setValue('');
    this.lineItemForm.controls['txtIGSTAmtDia'].setValue('0');
    this.lineItemForm.controls['txtIGSTPerDia'].setValue('0');
    this.txtDesign_data_hash = '';
    this.txtColor_data_hash = '';

  }

  // Text Color Validation
  txtColor_Validating(event) {
    try {
      if (this.txtColor_data_hash.toString() == this.lineItemForm.value.txtColor.trim()) {
        return;
      }
      this.comFunc.GeneralMasterCodeValidate(this.lineItemForm.value.txtColor, 'COLOR MASTER')

      this.txtColor_data_hash = this.lineItemForm.value.txtColor.trim();
    }
    catch (error) {
      this.snackBar.open(error.toString(), 'OK');
    }
  }

  // Text Size Validation
  txtSize_Validating(event) {
    try {
      if (this.txtSize_data_hash.toString() == this.lineItemForm.value.txtSize.toString() || this.lineItemForm.value.txtSize.toString() == '') {
        return;
      }
      this.comFunc.GeneralMasterCodeValidate(this.lineItemForm.value.txtColor, 'SIZE MASTER')
    }
    catch (error) {
      this.snackBar.open(error.toString(), 'OK');
    }
  }

  getMetalOrDiaCost(divisionMS) {
    const strMainVocType = 'POS'
    const branch = localStorage.getItem('userbranch');
    if (divisionMS == 'M') {
      // const blnBarcodeItem = this.comFunc.Null2BitValue(this.stockCodeValidationResult["barcodeitem"]);
      const blnBarcodeItem = true;

      if (blnBarcodeItem) {
        if (strMainVocType == 'POS' || 'EST' || 'PSR' || 'POSEX') {
          this.suntechApi.getPOS_Stockdetails_branchwise_Metalcost(this.lineItemForm.value.txtItemCode, branch)
            .subscribe((data) => {
              console.log(data);
              if (data.Status == 'Success') {

                this.divisionMSCostDetails = data.Result[0];
                console.log(this.comFunc.allCompanyParams);
                const price1LC = this.divisionMSCostDetails.BMC_PRICE1LC;
                const compCurrency = this.comFunc.allCompanyParams.COMPANYCURRENCY;

                if (this.currency == compCurrency) {
                  this.stockCost = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_MKG_RATECC) + this.comFunc.allbranchMaster.MINBRANCHPROFIT;
                  this.stockFCCost = this.stockCost;

                } else {
                  this.stockCost = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_MKG_RATECC) + this.comFunc.allbranchMaster.MINBRANCHPROFIT;
                  // alert(this.stockCost)
                  this.stockFCCost = this.comFunc.CCToFC(this.currency, this.comFunc.emptyToZero(this.txtCurRate));
                  // this.stockFCCost = this.comFunc.CCToFC(this.currency, this.comFunc.emptyToZero(this.txtCurRate), this.stockCost);
                }
                if (this.comFunc.allbranchMaster.DEFPRICEMPOS != null && this.comFunc.allbranchMaster.DEFPRICEMPOS.toString() != '') {
                  this.defPriceMPOS = parseInt(this.comFunc.allbranchMaster.DEFPRICEMPOS.toString());
                  switch (this.defPriceMPOS) {
                    case -1:
                      this.dblPrice1Val = 0.00;
                      break;
                    case 0:
                      this.dblPrice1Val = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_MKG_RATECC);
                      break;
                    case 1:
                      this.dblPrice1Val = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_PRICE1LC);
                      break;
                    case 2:
                      this.dblPrice1Val = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_PRICE2LC);
                      break;
                    case 3:
                      this.dblPrice1Val = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_PRICE3LC);
                      break;

                    case 4:
                      this.dblPrice1Val = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_PRICE4LC);
                      break;
                    case 5:
                      this.dblPrice1Val = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_PRICE5LC);
                      break;
                    case 6:
                      // WASTAGEPRICE1PER not comming in response
                      this.dblPrice1Val = this.comFunc.emptyToZero(this.divisionMSCostDetails.WASTAGEPRICE1PER);
                      this.dblPrice1Val = this.comFunc.emptyToZero(this.lineItemForm.value.txtMMetalRate) * this.dblPrice1Val / 100.00;
                      break;
                  }
                }
                if (this.comFunc.allbranchMaster.DEFPRICESTPOS != null && this.comFunc.allbranchMaster.DEFPRICESTPOS.toString() != '') {
                  this.defPriceMPOS = parseInt(this.comFunc.allbranchMaster.DEFPRICESTPOS.toString());
                  switch (this.defPriceMPOS) {
                    case -1:
                      this.dblSalesStonePrice1 = 0.00;
                      break;
                    case 0:
                      this.dblSalesStonePrice1 = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_STONE_RATECC);
                      break;
                    case 1:
                      this.dblSalesStonePrice1 = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_STONE_SALES_PRICE);
                      break;
                    case 2:
                      this.dblSalesStonePrice1 = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_STONE_SALES_PRICE2);
                      break;
                    case 3:
                      this.dblSalesStonePrice1 = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_STONE_SALES_PRICE3);
                      break;

                    case 4:
                      this.dblSalesStonePrice1 = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_STONE_SALES_PRICE4);
                      break;
                    case 5:
                      this.dblSalesStonePrice1 = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_STONE_SALES_PRICE5);
                      break;

                  }
                }

                // PRICE2PERCENT, PRICE2PERCENT not comming in response
                this.dblPrice1Per = this.comFunc.emptyToZero(this.divisionMSCostDetails.PRICE1PERCENT.trim());
                this.dblPrice2Per = this.comFunc.emptyToZero(this.divisionMSCostDetails.PRICE2PERCENT.trim());

                this.dblPrice1Val = this.comFunc.allCompanyParams.COMPANYCURRENCY != this.currency.trim() ? this.comFunc.CCToFC(this.currency, this.comFunc.emptyToZero(this.txtCurRate.trim())) : this.dblPrice1Val;
                // this.dblPrice1Val = this.comFunc.allCompanyParams.COMPANYCURRENCY != this.currency.trim() ? this.comFunc.CCToFC(this.currency, this.comFunc.emptyToZero(this.txtCurRate.trim()), this.dblPrice1Val) : this.dblPrice1Val;
                this.dblPrice2Val = this.comFunc.emptyToZero(this.divisionMSCostDetails.BMC_PRICE2LC);
                //dblPrice2Val = objSqlObjectTrans.Empty2zero(strArr[2].Trim());
                this.dblPrice2Val = this.comFunc.allCompanyParams.COMPANYCURRENCY != this.currency.trim() ? this.comFunc.CCToFC(this.currency, this.comFunc.emptyToZero(this.txtCurRate.trim())) : this.dblPrice2Val;
                // this.dblPrice2Val = this.comFunc.allCompanyParams.COMPANYCURRENCY != this.currency.trim() ? this.comFunc.CCToFC(this.currency, this.comFunc.emptyToZero(this.txtCurRate.trim()), this.dblPrice2Val) : this.dblPrice2Val;
                if (this.blnPOSFixed) {
                  this.lineItemForm.controls['txtMTotAmt'].setValue(this.dblPrice1Val.toString());
                }
                else {
                  // //Added by Rahul on 21-11-2020
                  // if (MyParent.strLoyaltyBrCode != string.Empty) {
                  //                               double MtlDiscRate = MyParent.dblMtlLoyaltyDiscPer / 100 * this.dblPrice1Val;
                  //   this.dblPrice1Val = this.dblPrice1Val - MtlDiscRate;
                  // }
                  // if (blnIsPromotional == false) {
                  //   txtMMkgRate.Text = this.dblPrice1Val.ToString();
                  // }

                }
                // dblSalesStonePrice1 = objCommonFunctions.CCToFC(MyParent.txtCurrency.Text.Trim(), objSqlObjectTrans.Empty2zero(MyParent.txtCurRate.Text.Trim()), dblSalesStonePrice1, objDbTrans);
                // txtMStoneRate.Text = dblSalesStonePrice1.ToString();
              }
            });
      }

    } else {
      this.suntechApi.getPOS_Stockdetails_branchwise_withcost(this.lineItemForm.value.txtItemCode, branch)
        .subscribe((data) => {
          console.log(data);
          if (data.Status == 'Success') {
            this.divisionMSCostDetails = data.Result[0];
          }
        })
    }

  }

}
}
