<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">Discount Authorization</h5>
  <button type="button" class="btn btn-close" style="border-color: none;" (click)="close()"><i class="feather icon-x"
      style="font-size: medium;color:
      #FFFFFF;"></i></button>
</div>

<div class="modal-body orderlockunlock">
  <div class="row" style="margin-bottom: 10px;">
    <div class="col-6">
      <mat-form-field >
        <mat-label>item</mat-label>
        <input type="input" matInput>
      </mat-form-field>
      <mat-form-field >
        <mat-label>Weight</mat-label>
        <input type="input" matInput>
      </mat-form-field>
      <mat-form-field >
        <mat-label>Pcs</mat-label>
        <input type="input" matInput formControlName="invoiceNo">
      </mat-form-field>

    </div>
    <div class="col-6">
      <mat-form-field >
        <mat-label>User Name</mat-label>
        <input type="input" matInput>
      </mat-form-field>
      <mat-form-field >
        <mat-label>Password</mat-label>
        <input type="input" matInput>
      </mat-form-field>
      <mat-form-field >
        <mat-label>Rate</mat-label>
        <input type="input" matInput formControlName="invoiceDate">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
    <mat-form-field >
      <mat-label>Remarks</mat-label>
      <input type="input" matInput>
    </mat-form-field>
  </div>
  </div>
<div class="row" style="padding-bottom: 10px; float:right; padding-top: 5px;">
  <div class="col-12">
    <button class="btn btn-primary" >Apply</button>&nbsp;
    <button class="btn btn-danger" (click)="close()">Cancel</button>&nbsp;
</div>


</div>
</div>
