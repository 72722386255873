 <div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">Order Lock & Unlock</h5>
  <button type="button" class="btn btn-close" style="border-color: none;"
    (click)="close()"><i class="feather icon-x" style="font-size: medium;color:
      #FFFFFF;"></i></button>
</div>
<div class="modal-body orderlockunlock" style="padding: 8px;padding-top: 8px;">
  <div class="row" style="margin-bottom: 10px;">
    <div class="col-4">
      <mat-form-field class="inherit">
        <mat-label>Branch</mat-label>
        <mat-select formControlName="mode">
          <mat-option>Branch 1</mat-option>
          <mat-option>Branch 2</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="radio-option inherit">
        <div class="box-title">
          Option
        </div>
        <mat-radio-group aria-labelledby="example-radio-group-label"
          class="example-radio-group">
          <mat-radio-button class="example-radio-button" value="1">Show All
            Locked Orders
          </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="2">Show All
            Unlocked
            Orders </mat-radio-button>

        </mat-radio-group>
      </div>
    </div>
    <div class="col-5 radio-option-filter">
      <div class="row box-title">
        Filter by
      </div>
      <mat-radio-group aria-labelledby="example-radio-group-label"
        class="example-radio-group">
        <mat-radio-button class="example-radio-button" value="1">Order No
          <mat-form-field>
            <mat-select>
              <mat-option value="one">First option</mat-option>
              <mat-option value="two">Second option</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input matInput>
          </mat-form-field>
        </mat-radio-button>
        <mat-radio-button class="example-radio-button" value="2">Order Age
          <mat-form-field>
            <input matInput>Days
          </mat-form-field>
        </mat-radio-button>
        <mat-radio-button class="example-radio-button" value="2">Period
          <mat-form-field>
              <input matInput [matDatepicker]="from">
              <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
              <mat-datepicker #from></mat-datepicker>
          </mat-form-field>
          <label style="padding-left: 5px;">To</label>
          <mat-form-field>
            <input matInput [matDatepicker]="to">
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to></mat-datepicker>
        </mat-form-field>
        </mat-radio-button>
      </mat-radio-group>

    </div>
    <div class="col-3 d-flex">
      <button class="btn btn-primary detail-btn">Show Details</button>&nbsp;
    </div>
  </div>

  <dx-data-grid
    #dataGrid
    id="gridContainer"
    [dataSource]="tasks"
    keyExpr="ID"
    [showBorders]="true">
    <dxo-row-dragging
      [allowReordering]="true"
      [showDragIcons]="true"></dxo-row-dragging>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-sorting mode="none"></dxo-sorting>
    <dxi-column *ngFor="let data of columnhead" dataField="{{data}}"
      [width]="auto" caption="{{data}}" [allowEditing]="true"
      alignment="center" backgroundcolor="#000">
    </dxi-column>
  </dx-data-grid>
  <div style="text-align: right; padding:10px;">
    <button class="btn btn-primary">Lock</button>&nbsp;
    <button class="btn btn-danger" (click)="close()">Close</button>&nbsp;
  </div>
</div>
