import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-consignment-item-detail',
  templateUrl: './consignment-item-detail.component.html',
  styleUrls: ['./consignment-item-detail.component.scss']
})
export class ConsignmentItemDetailComponent implements OnInit {
  selectedItemKeys:any[] = [];
  consigmentstockhead:any[] = ['SN','Branch','Stock Code','Allocation Ref','Supplier','Curr','City','Metal Wt','Purity','Pure Wt','Dia Carat','Stone Carat','Dia Amount','Stone Amount','Labour Charge'];

  selectionChanged(data: any) {
    this.selectedItemKeys = data.selectedRowKeys;
  }
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }
}
