<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">POS Customer Walkin</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i class="feather icon-x"></i></button>
</div>
<nav data-role="ribbonmenu">
  <ul class="tabs-holder">

      <li active><a href="#section-group">Home</a></li>
      <li><a href="">Settings</a></li>
  </ul>


  <div class="content-holder">
      <div class="section" id="section-group">
          <div class="group">
              <div data-role="button-group" data-cls-active="active">
                  <button class="ribbon-button">
                                  <span class="icon">
                                      <i class="fa-solid fa-file" style="color: #0d4f9e;"></i>
                                  </span>
                                  <span class="caption">New</span>
                              </button>
                  <button class="ribbon-button">
                                  <span class="icon" >
                                    <i class="fa fa-file-pen" style="color: #0d4f9e;"></i>
                                  </span>
                                  <span class="caption">Edit</span>
                              </button>
                  <button class="ribbon-button">
                                  <span class="icon">
                                    <i class="fa-solid fa-file-circle-minus" style="color: #0d4f9e;"></i>
                                  </span>
                                  <span class="caption">Delete</span>
                              </button>
                  <button class="ribbon-button">
                                  <span class="icon">
                                    <i class="fa-solid fa-rotate" style="color: #0d4f9e;"></i>
                                  </span>
                                  <span class="caption">Refresh</span>
                              </button>
              </div>
              <span class="title">File</span>
          </div>
          <div class="group">
              <div data-role="button-group" data-cls-active="active">
                  <button class="ribbon-button">
                            <span class="icon">
                                <i class="fa-solid fa-magnifying-glass" style="color: #0d4f9e;"></i>
                              </span>
                            <span class="caption">Search</span>
                        </button>
                  <button class="ribbon-button">
                            <span class="icon">
                              <i class="fa-solid fa-backward-step" style="color: #0d4f9e;"></i>
                            </span>
                            <span class="caption">First</span>
                        </button>
                  <button class="ribbon-button">
                            <span class="icon">
                              <i class="fa-solid fa-caret-left" style="color: #0d4f9e;"></i>
                            </span>
                            <span class="caption">Previous</span>
                        </button>
                  <button class="ribbon-button">
                            <span class="icon">
                              <i class="fa-solid fa-caret-right" style="color: #0d4f9e;"></i>
                            </span>
                            <span class="caption">Next</span>
                        </button>
                  <button class="ribbon-button">
                            <span class="icon">
                              <i class="fa-solid fa-forward-step" style="color: #0d4f9e;"></i>
                            </span>
                            <span class="caption">Last</span>
                        </button>
              </div>
              <span class="title">Search</span>
          </div>
          <div class="group">
              <div data-role="button-group" data-cls-active="active">

                  <button class="ribbon-button">
                          <span class="icon">
                            <i class="fa fa-print" style="color: #0d4f9e;"></i>
                          </span>
                          <span class="caption">Print</span>
                      </button>
              </div>
              <span class="title">Print</span>
          </div>
          <div class="group">
              <div data-role="button-group" data-cls-active="active">

                  <button class="ribbon-button" >
                      <span class="icon" >
                        <i class="fa-solid fa-circle-check" style="color: #0d4f9e;"></i>
                      </span>
                      <span class="caption">Audit Trial</span>
                  </button>
                  <button class="ribbon-button">
                    <span class="icon">
                      <i class="fa-solid fa-file-export" style="color: #0d4f9e;"></i>
                    </span>
                    <span class="caption">Post</span>
                </button>
              </div>

              <span class="title">Accounts</span>
          </div>
          <div class="group">
              <div data-role="button-group" data-cls-active="active">

                  <button class="ribbon-button">
                    <span class="icon">
                      <i class="fa-solid fa-envelopes-bulk" style="color: #0d4f9e;"></i>
                    </span>
                    <span class="caption">Atachment</span>
                </button>
                  <button class="ribbon-button" >
                  <span class="icon" >
                    <i class="fa-solid fa-file-lines" style="color: #0d4f9e;"></i>
                  </span>
                  <span class="caption">Log</span>
              </button>
              </div>

              <span class="title">Log</span>
          </div>
          <div class="group">
              <div data-role="button-group" data-cls-active="active" >
                  <button class="ribbon-button">
<span class="icon">
<i class="fa fa-x" style="color: red;"></i>
</span>
<span class="caption">Exit</span>
</button>
              </div>
              <span class="title">Exit</span>
          </div>
      </div>
  </div>
</nav>
<div class="modal-body ">
  <div class="row border">
    <div class="col-3">
      <mat-form-field>
        <mat-label>Voucher Type</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Voucher Date</mat-label>
        <input matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field style="padding-left: 2%;">
        <mat-label>Nationality</mat-label>
        <mat-icon matSuffix class="srchicon">search</mat-icon>
            <input matInput type="search" placeholder="search here">
    </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field style="padding-left: 2%;">
        <mat-label>Feedback</mat-label>
        <mat-icon matSuffix class="srchicon">search</mat-icon>
            <input matInput type="search" placeholder="search here">
    </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Mobile</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Email ID</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field style="padding-left: 2%;">
        <mat-label>Sales Man</mat-label>
        <mat-icon matSuffix class="srchicon">search</mat-icon>
            <input matInput type="search" placeholder="search here">
    </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">

    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Sales Achieved</mat-label>
        <mat-select>
          <mat-option value="one">Range 1</mat-option>
          <mat-option value="two">Range 2</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </div>
  <div class="row border">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Customer Address</mat-label>
        <textarea matInput type="search"></textarea>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field>
        <mat-label>Remarks</mat-label>
        <textarea matInput type="search"></textarea>
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field>
        <mat-label>Suggestions</mat-label>
        <textarea matInput type="search"></textarea>
      </mat-form-field>
    </div>




  </div>






  <div class="row">
    <div class="col-6">

    </div>
    <div class="col-6">
      <div class="footer-btn">

        <button class="btn btn-primary">Save</button>&nbsp;
        <button class="btn btn-danger" (click)="close()">Cancel</button>&nbsp;
      </div>

    </div>
  </div>

</div>


