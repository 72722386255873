<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">POS Action</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i class="feather icon-x" ></i></button>
</div>
<div class="modal-body posaction">
  <div class="container">

    <div class="row">
      <div class="col-6">
        <mat-form-field >
          <mat-label>Ref.</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field >
          <mat-label>Complaint Ref No</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field >
          <mat-label>Customer Code</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field >
          <mat-label>Name</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field >
          <mat-label>Mobile</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field >
          <mat-label>Email</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="container completion">
    <div class="row">
      <div class="col-4">
        <mat-form-field>
              <mat-label>Completion Date</mat-label>
              <input matInput [matDatepicker]="datefrom">
              <mat-datepicker-toggle matSuffix [for]="datefrom"></mat-datepicker-toggle>
              <mat-datepicker #datefrom></mat-datepicker>
          </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>Assigned To</mat-label>
          <mat-icon matSuffix class="srchicon">search</mat-icon>
              <input matInput type="search" placeholder="search here">
      </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>Assigned By</mat-label>
          <mat-icon matSuffix class="srchicon">search</mat-icon>
              <input matInput type="search" placeholder="search here">
      </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <mat-form-field>
          <mat-label>Call</mat-label>
          <mat-select>
            <mat-option value="one">+91</mat-option>
            <mat-option value="two">+99</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        <div class="col-4">
        <mat-form-field>
          <input matInput>
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field>
          <mat-label>SMS</mat-label>
          <mat-select>
            <mat-option value="one">+91</mat-option>
            <mat-option value="two">+99</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        <div class="col-4">
        <mat-form-field>
          <input matInput>
        </mat-form-field>
      </div>
    </div>

  </div>

  <div class="footer-btn">
    <button class="btn btn-primary">Save</button>&nbsp;
    <button class="btn btn-danger" (click)="close()">Cancel</button>&nbsp;
  </div>
</div>
