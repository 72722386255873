import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.scss']
})
export class CustomerFeedbackComponent implements OnInit {
  complaint:any[];
  feedback:any[];
  grpcomplaint:[];
  grpfeedback:[];
  actionarea:[];
  complaintcolumnhead:any[] = ['Complaint Date','Ref.No','Category','Staff Courtesy','Product Knowledge','Shop Location','Quality','Experiance','Sales Person','Comments',' '];
  feedbackcolumnhead:any[] = ['Feedback Date','Ref.No','Product Selection','Service','Making Charge','Brand Name','Buyback Policy','Location Parking','Sales Person','Comments','Action'];
  grpcomplaintcoloumnhead:any[] = ['Complaint Date','Ref.No','Code','Customer Name','Category','Staff Courtesy','Product Knowledge','Shop Location','Quality','Experiance','Sales Person','Comments','Status','Add'];
  grpfeedbackcoloumnhead:any[] = ['Feedback Date','Ref.No','CODE','Customer Name','Product Selection','Service','Making Charge','Brand Name','Buyback Policy','Location Parking','Sales Person','Remarks',];
  actionareacoloumnhead:any[] = ['Completion Date','Call','SMS','Email','Visit','Assign To','Assign By','Remarks'];
  constructor(
      private activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
    }

    close() {
      this.activeModal.close();
    }

}
