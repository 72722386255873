<div class="row mb-3">
  <div class="col-sm-12 border-radius-bltl border-radius-brtr" style="background:#eee;">
    <h5>Old Gold Purchase</h5>
  </div>
</div>
<!-- <div class="gridbox">
<mat-grid-list  cols="2" rowHeight="10:1" gutterSize="10px" >
  <mat-grid-tile class="color">
    <mat-form-field class="example-full-width">
      <mat-label >Metal Rate</mat-label>
      <input type="input" matInput>
    </mat-form-field>
  </mat-grid-tile>
  <mat-grid-tile class="color" >
    <mat-form-field class="example-full-width">
      <mat-label>Stock Code</mat-label>
      <input type="input" matInput>
    </mat-form-field>
  </mat-grid-tile>
  <mat-grid-tile class="color"><mat-form-field >
    <mat-label>Email</mat-label>
    <input type="input" matInput>
  </mat-form-field></mat-grid-tile>
  <mat-grid-tile class="color"><mat-form-field >
    <mat-label>Email</mat-label>
    <input type="input" matInput>
  </mat-form-field></mat-grid-tile>
</mat-grid-list>
</div> -->
<div class="container gridbox">
  <div class="row">
    <div class="col-4">
      <mat-form-field class="example-full-width">
        <mat-label>Metal Rate</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="example-full-width">
        <mat-label>Stock Code</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-checkbox class="example-margin">
        <mat-form-field>
          <mat-label>Outside Gold</mat-label>
          <mat-icon matSuffix class="srchicon">search</mat-icon>
          <input matInput type="search" placeholder="search here">
        </mat-form-field>
      </mat-checkbox>

    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <mat-form-field class="example-full-width">
        <mat-label>Description</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="example-full-width">
        <mat-label>Supplier</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field>
        <mat-label>Location</mat-label>
        <mat-icon matSuffix class="srchicon">search</mat-icon>
        <input matInput type="search" placeholder="search here">
      </mat-form-field>
    </div>
  </div>
  <!-- quantity start -->
  <u><h6>Quantity</h6></u>
  <div class="row">
    <div class="col-3">
      <mat-form-field class="example-full-width">
        <mat-label>Pieces</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width">
        <mat-label>Gross Weight</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width">
        <mat-label>Stone Weight</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width">
        <mat-label>Purity</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width">
        <mat-label>Pure Weight</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width">
        <mat-label>Mud Weight</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width">
        <mat-label>Net Weight</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width">
        <mat-label>Chargable Weight</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
  </div>
  <!-- quantity end -->

  <div class="row">
    <div class="col-6">
      <!-- making charges start -->
      <u><h6>Making Charges</h6></u>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="example-full-width">
            <mat-label>Rate</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="example-full-width">
            <mat-label>Amount</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <!-- making charges end -->
    </div>
    <div class="col-6">
      <!-- metal values start -->
      <u><h6>Metal Values</h6></u>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="example-full-width">
            <mat-label>Rate</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="example-full-width">
            <mat-label>Amount</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <!-- metal values end -->
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <!-- stone charges start -->
      <u><h6>Stone Charges</h6></u>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="example-full-width">
            <mat-label>Rate</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="example-full-width">
            <mat-label>Amount</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <!-- stone charges end -->
    </div>
    <div class="col-6">
      <!-- Stone and purity values start -->
      <u><h6>Stone & Purity Differences</h6></u>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="example-full-width">
            <mat-label>Purity Difference</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="example-full-width">
            <mat-label>Stone Difference</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <!-- Stone and purity values end -->
    </div>
  </div>

  <div class="row">
    <div class="col-9">
      <!-- wastage  -->
      <u><h6>Wastage</h6></u>
      <div class="row">
        <div class="col-4">
          <mat-form-field class="example-full-width">
            <mat-label>%</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="example-full-width">
            <mat-label>Quantity</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>

      <div class="col-4">
          <mat-form-field class="example-full-width">
            <mat-label>Amount</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
    </div>
      <!-- wastage end -->
    <div class="col-3">
      <!-- bag details start -->
      <u><h6>Bag Details</h6></u>
      <div class="row">

        <div class="col">
          <mat-form-field class="example-full-width">
            <mat-label>Bag No:</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <!-- bag details  end -->
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <u><h6>Net Values</h6></u>
      <mat-form-field class="example-full-width">
        <mat-label>Amount</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <u><h6>Remarks</h6></u>
      <mat-form-field class="example-full-width">
        <mat-label>Remarks</mat-label>
        <textarea type="input" matInput></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="footer-btn">
    <button class="btn btn-primary">Continue</button>&nbsp;
    <button class="btn btn-primary">Save</button>&nbsp;
    <button class="btn btn-danger">Cancel</button>&nbsp;
  </div>
</div>
<div class="container gridbox mt-5">
  <div class="row">
    <div class="col-4">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Metal Rate</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Stock Code</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-checkbox class="example-margin">
        <mat-form-field  appearance="outline">
          <mat-label>Outside Gold</mat-label>
          <mat-icon matSuffix class="srchicon">search</mat-icon>
          <input matInput type="search" placeholder="search here">
        </mat-form-field>
      </mat-checkbox>

    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Description</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Supplier</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field  appearance="outline">
        <mat-label>Location</mat-label>
        <mat-icon matSuffix class="srchicon">search</mat-icon>
        <input matInput type="search" placeholder="search here">
      </mat-form-field>
    </div>
  </div>
  <!-- quantity start -->
  <u><h6>Quantity</h6></u>
  <div class="row">
    <div class="col-3">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Pieces</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Gross Weight</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Stone Weight</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Purity</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Pure Weight</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Mud Weight</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Net Weight</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Chargable Weight</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
  </div>
  <!-- quantity end -->

  <div class="row">
    <div class="col-6">
      <!-- making charges start -->
      <u><h6>Making Charges</h6></u>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Rate</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Amount</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <!-- making charges end -->
    </div>
    <div class="col-6">
      <!-- metal values start -->
      <u><h6>Metal Values</h6></u>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Rate</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Amount</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <!-- metal values end -->
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <!-- stone charges start -->
      <u><h6>Stone Charges</h6></u>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Rate</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Amount</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <!-- stone charges end -->
    </div>
    <div class="col-6">
      <!-- Stone and purity values start -->
      <u><h6>Stone & Purity Differences</h6></u>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Purity Difference</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Stone Difference</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <!-- Stone and purity values end -->
    </div>
  </div>

  <div class="row">
    <div class="col-9">
      <!-- wastage  -->
      <u><h6>Wastage</h6></u>
      <div class="row">
        <div class="col-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>%</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Quantity</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>

      <div class="col-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Amount</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
    </div>
      <!-- wastage end -->
    <div class="col-3">
      <!-- bag details start -->
      <u><h6>Bag Details</h6></u>
      <div class="row">

        <div class="col">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Bag No:</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <!-- bag details  end -->
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <u><h6>Net Values</h6></u>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Amount</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <u><h6>Remarks</h6></u>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Remarks</mat-label>
        <textarea type="input" matInput></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="footer-btn">
    <button class="btn btn-primary">Continue</button>&nbsp;
    <button class="btn btn-primary">Save</button>&nbsp;
    <button class="btn btn-danger">Cancel</button>&nbsp;
  </div>
</div>
