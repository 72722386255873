<div>

  <h5 mat-dialog-title>{{title}}</h5>
  <div mat-dialog-content>
    {{msg}}
  </div>
  <div mat-dialog-actions class="text-right mt-3">

    <button mat-button mat-dialog-close (click)="submit('No')" class="mr-3 btn-danger" [hidden]="okBtn">No</button>
    <button mat-button cdkFocusInitial (click)="submit('Yes')" class="mr-3 btn-primary" [hidden]="okBtn">Yes</button>
    <button mat-button id="okBtn" cdkFocusInitial (click)="submit('OK')" class="mr-3 btn-primary"
      [hidden]="!okBtn">OK</button>
    <!-- <button mat-button  cdkFocusInitial (click)="submit(btnTitle)" class="mr-3 btn-primary" >{{btnTitle}}</button> -->

  </div>

</div>
