import { Component, OnInit,enableProdMode } from '@angular/core';
import { Bill, Service } from '../consigment.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

@Component({
  selector: 'app-estimation-list',
  templateUrl: './estimation-list.component.html',
  styleUrls: ['./estimation-list.component.scss'],
  providers: [Service],
})
export class EstimationListComponent {


  bills: Bill[] | any;

  constructor(service: Service,
    private activeModal: NgbActiveModal) {

    this.bills = service.getBill();
    console.log(this.bills);
  }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

}
