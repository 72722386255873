import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogbox',
  templateUrl: './dialogbox.component.html',
  styleUrls: ['./dialogbox.component.scss']
})
export class DialogboxComponent implements OnInit {

  title: String = '';
  msg: String = '';
  btnTitle: String = '';
  okBtn: boolean;

  constructor(
    public renderer: Renderer2,
    public dialogRef: MatDialogRef<DialogboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.title = this.data.title;
    this.msg = this.data.msg;
    this.okBtn = this.data.okBtn;
    setTimeout(() => {
      // this.renderer.selectRootElement('#okBtn').focus();
    }, 100);
  }


  ngOnInit(): void {
    console.log(this.title);

  }

  submit(values) {
    this.dialogRef.close(values);
  }
}
