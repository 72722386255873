<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">POS Sales Commission</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i
      class="feather icon-x"></i></button>
</div>
<div class="modal-body ">
  <div class="row">
    <div class="col">
      <mat-form-field >
        <mat-label>Invoice Amount</mat-label>
        <input type="input" matInput>
      </mat-form-field>

      <dx-data-grid #dataGrid id="gridContainer" keyExpr="ID" [showBorders]="true" >
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxi-column *ngFor="let data of columnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
          [allowEditing]="true" alignment="center" backgroundcolor="#000" >
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>
    <div class="row border">
      <div class="col-6">
        <mat-form-field >
          <mat-label>Sales Man</mat-label>
          <input type="input" matInput>
        </mat-form-field>
        <mat-form-field >
          <mat-label>Percentage</mat-label>
          <input type="input" matInput>
        </mat-form-field>
        <mat-form-field >
          <mat-label>Amount.LC</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
      <div class="col-btn col-6">
            <div class="col-btn-row">
              <button class="btn btn-primary col-btn">Continue</button><br>
            </div>
            <div class="col-btn-row">
              <button class="btn btn-primary col-btn">Save</button><br>
            </div>
            <div class="col-btn-row">
              <button class="btn btn-danger col-btn">Cancel</button><br>
            </div>
      </div>
    </div>
  <div class="footer-btn">
    <button class="btn btn-primary">Add</button>&nbsp;
    <button class="btn btn-primary">Delete</button>&nbsp;
    <button class="btn btn-danger" (click)="close()">Close</button>&nbsp;
  </div>
</div>
