import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuntechapiService } from './../../suntechapi.service';
import { Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public snackBarRef: any;
  public validateState = 0;
  changePasswordForm: FormGroup;
  // showDialog: boolean = false;
  // public dialogRef: any;
  @Inject(MAT_DIALOG_DATA) public data: any

  @Input() public showDialog: boolean = false;

  // public dialogRef;
  constructor(
    private snackBar: MatSnackBar,
    private suntechApi: SuntechapiService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,

    // public dialogRef: MatDialogRef<ChangePasswordComponent>,
    // public dialogRef: MatDialogRef,
  ) {

    // if (this.data != null)
    //   this.showDialog = this.data.showDialog;
  }

  closeDialog() {
    // this.dialogRef.close();
      this.activeModal.close();
  }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(3)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(3)]],
    });
    // this.changePasswordForm = this.fb.group({
    //   username: ['', [Validators.required]],
    //   password: ['', [Validators.required, Validators.minLength(3)]],
    //   confirmPassword: ['', Validators.required, Validators.minLength(3)],
    // }, { validator: this.checkPasswords });
  }

  // checkPasswords(group: FormGroup) {
  //   const password = group.get('password').value;
  //   const confirmPassword = group.get('confirmPassword').value;
  //   return password === confirmPassword ? null : { notSame: true };
  // }

  checkUserName(event: any) {
    this.validateState = 0;

    console.log(event.target.value);
    let userName = event.target.value;
    if (userName != '') {
      this.snackBarRef = this.snackBar.open('Validating Username ...');
      this.suntechApi.getUserName(userName).subscribe((resp) => {
        console.log(resp);
        if (resp.status == 'Success') {
          this.validateState = 1;
        } else {
          this.validateState = 0;
        }
        this.snackBar.dismiss();
      });
    }
  }
  updatePassword() {
    if (this.validateState == 1) {

      if (!this.changePasswordForm.invalid) {
        if (this.changePasswordForm.value.password == this.changePasswordForm.value.confirmPassword) {

          this.suntechApi
            .updatePassword(this.changePasswordForm.value.username, this.changePasswordForm.value.password)
            .subscribe((resp) => {
              console.log(resp);
              if (resp.Status == 'Success') {
                this.snackBarRef = this.snackBar.open(resp.Message);
                // this.snackBarRef = this.snackBar.open('Password Updated Successfully!');
              } else {
                this.snackBarRef = this.snackBar.open(resp.Message);
              }
            });

        } else {
          this.snackBarRef = this.snackBar.open(' Passwords do not match');
        }
      }
      else {
        this.snackBarRef = this.snackBar.open('Please fill all fields');
      }
    } else {
      this.snackBarRef = this.snackBar.open('Username not valid');

    }

  }
}
