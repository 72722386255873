<div class="row mb-3">
  <div class="col-sm-12 border-radius-bltl border-radius-brtr" style="background:#eee;">
    <h5>POS Purchase Direct</h5>
  </div>
</div>




<div class="container" style="padding: 0px;">
  <div class="cds-billing">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12 ribbonmenulink">
          <nav data-role="ribbonmenu">
            <ul class="tabs-holder">
              <li><a href="#section-group">Home</a></li>
            </ul>

            <div class="content-holder">
              <div class="section" id="section-group">
                <div class="group">
                  <div data-role="button-group" data-cls-active="active">
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-file" ></i>
                      </span>
                      <span class="caption">New</span>
                    </button>
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa fa-file-pen" ></i>
                      </span>
                      <span class="caption">Edit</span>
                    </button>
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-file-circle-minus" ></i>
                      </span>
                      <span class="caption">Delete</span>
                    </button>
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-rotate" ></i>
                      </span>
                      <span class="caption">Refresh</span>
                    </button>
                  </div>
                  <span class="title">File</span>
                </div>
                <div class="group">
                  <div data-role="button-group" data-cls-active="active">
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-magnifying-glass" ></i>
                      </span>
                      <span class="caption">Search</span>
                    </button>
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-backward-step" ></i>
                      </span>
                      <span class="caption">First</span>
                    </button>
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-caret-left" ></i>
                      </span>
                      <span class="caption">Previous</span>
                    </button>
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-caret-right" ></i>
                      </span>
                      <span class="caption">Next</span>
                    </button>
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-forward-step" ></i>
                      </span>
                      <span class="caption">Last</span>
                    </button>
                  </div>
                  <span class="title">Search</span>
                </div>
                <div class="group">
                  <div data-role="button-group" data-cls-active="active">
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa fa-print" ></i>
                      </span>
                      <span class="caption">Print</span>
                    </button>
                  </div>
                  <span class="title">Print</span>
                </div>
                <div class="group">
                  <div data-role="button-group" data-cls-active="active">
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-circle-check" ></i>
                      </span>
                      <span class="caption">Audit Trial</span>
                    </button>
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-file-export" ></i>
                      </span>
                      <span class="caption">Post</span>
                    </button>
                  </div>

                  <span class="title">Accounts</span>
                </div>
                <div class="group">
                  <div data-role="button-group" data-cls-active="active">
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-envelopes-bulk" ></i>
                      </span>
                      <span class="caption">Atachment</span>
                    </button>
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa-solid fa-file-lines" ></i>
                      </span>
                      <span class="caption">Log</span>
                    </button>
                  </div>

                  <span class="title">Log</span>
                </div>
                <div class="group">
                  <div data-role="button-group" data-cls-active="active">
                    <button class="ribbon-button">
                      <span class="icon">
                        <i class="fa fa-x" style="color: red;"></i>
                      </span>
                      <span class="caption">Exit</span>
                    </button>
                  </div>
                  <span class="title">Exit</span>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div class="row border">
        <div class="mt-2">
          <h4>Invoice Details</h4>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="example-full-width">
              <mat-label>Vocher Type</mat-label>
              <input type="input" matInput>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Vocher Date</mat-label>
              <input matInput [matDatepicker]="vocdate">
              <mat-datepicker-toggle matSuffix [for]="vocdate"></mat-datepicker-toggle>
              <mat-datepicker #vocdate></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Party Code</mat-label>
              <mat-icon matSuffix class="srchicon">search</mat-icon>
              <input matInput type="search" placeholder="search here">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Party Curr</mat-label>
              <mat-icon matSuffix class="srchicon">search</mat-icon>
              <input matInput type="search" placeholder="search here">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Customer</mat-label>
              <mat-icon matSuffix>search <button class="btn btn-primary
                  newbtn">NEW</button></mat-icon>
              <input matInput type="search" placeholder="search here">
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <mat-label>Mobile</mat-label>
              <input type="input" matInput>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="example-full-width">
              <mat-label>Item Curr</mat-label>
              <input type="input" matInput>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Credit Days</mat-label>
              <input matInput [matDatepicker]="vocdate">
              <mat-datepicker-toggle matSuffix [for]="vocdate"></mat-datepicker-toggle>
              <mat-datepicker #vocdate></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Sales Man</mat-label>
              <mat-icon matSuffix class="srchicon">search</mat-icon>
              <input matInput type="search" placeholder="search here">
            </mat-form-field>
            <mat-form-field class="example-half-width">
              <mat-label>Suv Inv No</mat-label>
              <input type="input" matInput>
            </mat-form-field>
            <mat-form-field class="example-half-width">
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="date">
              <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
              <mat-datepicker #date></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Cust Name</mat-label>
              <input type="input" matInput>
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <mat-label>Email</mat-label>
              <input type="input" matInput>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Cust Id</mat-label>
              <input type="input" matInput>
            </mat-form-field>
          </div>
          <div class="col-4">
            <dx-data-grid
              id="gridContainer"
              [showBorders]="true">
              <dxo-paging [enabled]="false"></dxo-paging>

              <dxo-selection mode="single"></dxo-selection>
              <dxi-column *ngFor="let data of columnhead"
                dataField="{{data}}" [width]="auto" caption="{{data}}"
                [allowEditing]="true" alignment="center"
                backgroundcolor="#000">
              </dxi-column>

            </dx-data-grid>
            <h6 class="pt-2">Point Of Sales Control</h6>
            <mat-form-field class="example-full-width">
              <mat-label>Name</mat-label>
              <input type="input" matInput>
            </mat-form-field><mat-form-field class="example-full-width">
              <mat-label>Address</mat-label>
              <textarea type="input" matInput rows="5"></textarea>
            </mat-form-field>
          </div>

        </div>
      </div>
      <div class="row border">
        <div class="col">
          <div class="mt-2">
            <h4>Line Items</h4>
          </div>
          <div class="row">
            <div class="col">
              <button type="button" class="btn btn-outline-secondary"><i
                  class="feather icon-plus"></i> </button>
              <button type="button" class="btn btn-outline-secondary danger"
                style="margin-left: 10px;"><i class="feather icon-x"
                  ></i> </button>
            </div>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col">
              <dx-data-grid
                id="gridContainer"
                [showBorders]="true">
                <dxo-paging [enabled]="false"></dxo-paging>

                <dxo-selection mode="single"></dxo-selection>
                <dxi-column *ngFor="let data of lineitemshead"
                  dataField="{{data}}" [width]="auto" caption="{{data}}"
                  [allowEditing]="true" alignment="center"
                  backgroundcolor="#000">
                </dxi-column>

              </dx-data-grid>
            </div>
          </div>
        </div>
      </div>
      <div class="row border">
        <div class="col">
          <div class="row">
            <div class="col">
              <mat-form-field class="example-full-width">
                <mat-label>Naration</mat-label>
                <textarea matInput rows="5"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <mat-label>Party Currency</mat-label>
                <input matInput>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <mat-label>Amount</mat-label>
                <input matInput>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <mat-label>Rnd of Amount</mat-label>
                <input matInput>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <mat-label>Rnd Net Amount</mat-label>
                <input matInput>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <mat-label>Other Amt</mat-label>
                <input matInput>
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field class="example-full-width">
                <mat-label>Gross Amt</mat-label>
                <input matInput>
              </mat-form-field>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="footer-btn">
      <button class="btn btn-primary">Save</button>&nbsp;
      <button class="btn btn-danger">Cancel</button>&nbsp;
    </div>
  </div>
</div>
