<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">POS Feedback</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i
      class="feather icon-x"></i></button>
</div>
<div class="modal-body ">
  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-label>Product Selection</mat-label>
        <mat-select>
          <mat-option value="one">Prod 1</mat-option>
          <mat-option value="two">Prod 2</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
      <mat-label>Service</mat-label>
        <mat-select>
          <mat-option value="one">Serv 1</mat-option>
          <mat-option value="two">Serv 2</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Making charge</mat-label>
          <mat-select>
            <mat-option value="one">Slab 1</mat-option>
            <mat-option value="two">Slab 2</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Brand Name</mat-label>
            <mat-select>
              <mat-option value="one">Brand 1</mat-option>
              <mat-option value="two">Brand 2</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Buy-Back Policy</mat-label>
              <mat-select>
                <mat-option value="one">Policy 1</mat-option>
                <mat-option value="two">Policy 2</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Location And Parking Facility</mat-label>
                <mat-select>
                  <mat-option value="one">Location 1</mat-option>
                  <mat-option value="two">Location 2</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Remarks</mat-label>
                <textarea matInput type="search"></textarea>
              </mat-form-field>
    </div>
  </div>
  <div class="footer-btn">
    <button class="btn btn-primary">Save</button>&nbsp;
    <button class="btn btn-danger" (click)="close()">Cancel</button>&nbsp;
  </div>
</div>
