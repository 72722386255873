import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pos-feedback',
  templateUrl: './pos-feedback.component.html',
  styleUrls: ['./pos-feedback.component.scss']
})
export class PosFeedbackComponent implements OnInit {
  constructor(
    private activeModal: NgbActiveModal

  ) { }

  ngOnInit(): void {
  }
  close() {
    this.activeModal.close();
  }

}
