import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-pos-salesman-commission-list',
  templateUrl: './pos-salesman-commission-list.component.html',
  styleUrls: ['./pos-salesman-commission-list.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],


})
export class PosSalesmanCommissionListComponent implements OnInit {

  columnhead:any[] = ['SALESMAN CODE','SALESMAN NAME','EFFECTIVELY','SP AC CODE','MAKINBG CHARGE','SALES VALUE','EXP ALLOWED','NET GP','COMMISION AMOUNT'];
  constructor(
    private activeModal: NgbActiveModal

  ) { }

  ngOnInit(): void {
  }
  close() {
    this.activeModal.close();
  }

}
