import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pos-sales-commission',
  templateUrl: './pos-sales-commission.component.html',
  styleUrls: ['./pos-sales-commission.component.scss']
})
export class PosSalesCommissionComponent implements OnInit {
  columnhead:any[] = ['Sr.No','Sales Man','Amount'];
  constructor(
    private activeModal: NgbActiveModal

  ) { }

  ngOnInit(): void {
  }
  close() {
    this.activeModal.close();
  }

}
