<div class="row mb-3">
  <div class="col-sm-12 border-radius-bltl border-radius-brtr" style="background:#eee;">
    <h5>Customer Enquiry</h5>
  </div>
</div>

<div class="container">
  <!-- left side box -->
  <div class="row ">
    <div class="col-md-6 left-col">

      <div class="row sidetop">
        <div class="col ">
          <!-- <button class="btn-disabled" disabled>Filter By</button> -->
          <h5>Filter By</h5>
        </div>
        <div class="col">
          <button class="btn btn-primary">Branch & Division</button>
        </div>
      </div>

      <div class="row border">
        <div class="col">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>Spouse</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div> <div class="col">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>

      <div class="row border">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Customer From</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Customer To</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Birth Month & Date</mat-label>
            <input matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Month</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Date</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Wedding Month & Date</mat-label>
            <input matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Month</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Date</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <div class="row border">
        <div class="col top-pad">
          <mat-form-field>
            <mat-label>SMS Message</mat-label>
            <textarea type="input" matInput></textarea>
          </mat-form-field>
        </div>
        <div class="col-3 top-pad">
          <button class="btn btn-primary">Select Customer</button>&nbsp;
        </div>
      </div>
      <!-- end -->
    </div>
    <div class="col-md-6 right-col">
      <!-- right side box -->
      <div class="row border">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Tele.(Res)</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Mobile</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Marital Status</mat-label>
            <mat-select>
              <mat-option value="one">Yes</mat-option>
              <mat-option value="two">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Gender</mat-label>
            <mat-select>
              <mat-option value="one">Male</mat-option>
              <mat-option value="two">Female</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Country</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Type</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Nationality</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Category</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>City</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Cust</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Religion</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Division</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>State</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Loyality</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Sales Date From</mat-label>
            <input matInput [matDatepicker]="salefrom">
            <mat-datepicker-toggle matSuffix [for]="salefrom"></mat-datepicker-toggle>
            <mat-datepicker #salefrom></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Sales Date To</mat-label>
            <input matInput [matDatepicker]="saleto">
            <mat-datepicker-toggle matSuffix [for]="saleto"></mat-datepicker-toggle>
            <mat-datepicker #saleto></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <!-- end -->

    </div>

  </div>
  <!-- bottom table -->
  <div class="row">
    <div class="col">
      <dx-data-grid
        id="gridContainer"
        [showBorders]="true"
        (onSelectionChanged)="selectionChanged($event)">
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-editing
          mode="cell"
          [allowUpdating]="true">
        </dxo-editing>
        <dxo-selection mode="multiple"></dxo-selection>
        <dxi-column *ngFor="let data of customerenquiryhead"
          dataField="{{data}}" [width]="auto" caption="{{data}}"
          [allowEditing]="true" alignment="center" backgroundcolor="#000">
        </dxi-column>

      </dx-data-grid>
      <div class="row footer-btn">
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Output To</mat-label>
            <mat-select>
              <mat-option value="one">Option-1</mat-option>
              <mat-option value="two">Option-2</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Output Type</mat-label>
            <mat-select>
              <mat-option value="one">Option-1</mat-option>
              <mat-option value="two">Option-2</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <button class="btn btn-primary">Ok</button>&nbsp;
        </div>
        <div class="col-md-3">
          <button class="btn btn-danger">Cancel</button>&nbsp;
        </div>
      </div>

    </div>

  </div>
  <!-- end table -->

</div>
