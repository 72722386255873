import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-purchase-diractetail',
  templateUrl: './purchase-diractetail.component.html',
  styleUrls: ['./purchase-diractetail.component.scss']
})
export class PurchaseDiractetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
