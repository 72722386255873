import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-consigment-stock',
  templateUrl: './consigment-stock.component.html',
  styleUrls: ['./consigment-stock.component.scss']
})
export class ConsigmentStockComponent implements OnInit {
  selectedItemKeys:any[] = [];
  consigmentstockhead:any[] = ['Stock Code','From','Supplier','Curr.Code','Curr.Rate','Stock Qty','Rtn/Pur','Rate','Disc %','Net Rate','MkgCost','LandedCost','AllocationRef'];



  selectionChanged(data: any) {
    this.selectedItemKeys = data.selectedRowKeys;
  }
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

}
