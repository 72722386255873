import { Component, OnInit } from '@angular/core';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-pos-customer-enquiry',
  templateUrl: './pos-customer-enquiry.component.html',
  styleUrls: ['./pos-customer-enquiry.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PosCustomerEnquiryComponent implements OnInit {
  selectedItemKeys:any[] = [];
  customerenquiryhead:any[] = ['Code','Name','Gender','Country','Nationality','City','State','Type','Category'];
  constructor(
  ) { }

  ngOnInit(): void {
  }
  selectionChanged(data: any) {
    this.selectedItemKeys = data.selectedRowKeys;
  }

}
