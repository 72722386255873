import { Component, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-pos-purchase-direct',
  templateUrl: './pos-purchase-direct.component.html',
  styleUrls: ['./pos-purchase-direct.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class PosPurchaseDirectComponent implements OnInit {
  columnhead:any[] = ['Karat','Sale Rate','Purchase Rate'];
  lineitemshead:any[] = ['Stock Code','Pcs','Gr. Wt','Purity','Pure Wt','Mkg. Wt','Mkg. Amount','Metal Amt.','Steel Amt.','Wastage','Wastage','Total'];
  constructor() { }

  ngOnInit(): void {
  }

}
