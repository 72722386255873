<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">Net Sales Summary</h5>
  <button type="button" class="btn btn-close" style="border-color: none;" (click)="close()"><i class="feather icon-x"
      style="font-size: medium;color:
      #FFFFFF;"></i></button>
</div>

<div class="modal-body orderlockunlock">
  <div class="row" style="margin-bottom: 10px;">
    <div class="col-8">
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Customer</mat-label>
            <input type="input" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tel (Res)</mat-label>
            <input type="input" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Fax.No</mat-label>
            <input type="input" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>City</mat-label>
            <input type="input" matInput>
          </mat-form-field>

        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Mobile</mat-label>
            <input type="input" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Tel (Off)</mat-label>
            <input type="input" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Po Box</mat-label>
            <input type="input" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Country</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>


      <mat-form-field>
        <mat-label>Email</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>

  <div class="col-4">
    <div class="col photocoloumn">
      <h5 class="h5header">Photo</h5>
    </div>
    <div class="container completion">
      <div class="justify-center">
        <div class="col-10  image-container">

        </div>
      </div>

    </div>
  </div>

</div>



<div class="row" style="margin-bottom: 10px;">
  <div class="col-12">

    <dx-data-grid #dataGrid id="gridContainer" [dataSource]="invoicebasic" keyExpr="ID" [showBorders]="true">
      <dxo-paging [enabled]="false"></dxo-paging>


      <dxi-column *ngFor="let data of invoicebasiccolumnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
        [allowEditing]="true" alignment="center" backgroundcolor="#000">
      </dxi-column>
    </dx-data-grid>
  </div>
</div>
<div class="col photocoloumn">
  <h5 class="h5header">Invoice Details</h5>
</div>



<ngb-tabset [destroyOnHide]="false" #tabset="ngbTabset">

  <ngb-tab title="Invoice Details" id="invoice">

  </ngb-tab>

  <ngb-tab>
    <ng-template ngbTabTitle id="advanced">Advanced Details</ng-template>
    <ng-template ngbTabContent>
      <div class="row" style="margin-bottom: 10px;">
        <div class="col-12">

          <dx-data-grid #dataGrid id="gridContainer" [dataSource]="advanced" keyExpr="ID" [showBorders]="true">
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-editing mode="cell" [allowUpdating]="true">
            </dxo-editing>
            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-scrolling mode="virtual"></dxo-scrolling>

            <dxi-column *ngFor="let data of advancedcolumnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
              [allowEditing]="true" alignment="center" backgroundcolor="#000">
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab>
    <ng-template ngbTabTitle id="loyalty">Loyalty Trans.Details</ng-template>
  </ngb-tab>

</ngb-tabset>

<div class="row">
  <div class="col-3">
    <button class="btn btn-outline-primary">Expot To Excel</button>&nbsp;
  </div>
  <div class="col-3">
    <mat-form-field>
      <mat-label>Total No. Sales</mat-label>
      <input type="input" matInput>
    </mat-form-field>
  </div>
  <div class="col-3">
    <mat-form-field>
      <mat-label>Total No. Sales Return</mat-label>
      <input type="input" matInput>
    </mat-form-field>
  </div>
  <div class="col-3">
    <div style="text-align: right; padding:10px;">
      <!-- <button class="btn btn-primary">Lock</button>&nbsp; -->
      <button class="btn btn-danger" (click)="close()">Close</button>&nbsp;
    </div>
  </div>
</div>

</div>
