<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">POS Scheme Master</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i class="feather icon-x"></i></button>
</div>
<div class="modal-body ">
  <div class="row border">
    <div class="col-3">
      <mat-form-field>
        <mat-label>Party Code</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Party Currency</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Metal Rate</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Number</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

  </div>
  <div class="row border">

      <div class="tool-bar">
        <!-- <button type="button" class="btn btn-outline-secondary"  ><i class="feather icon-plus" style="font-size: 15px; color: green;"></i> </button> -->
        <button type="button" class="btn btn-outline-secondary"><i class="feather icon-x close"></i> </button>
      </div>

    <div class="col-3">
      <mat-form-field>
        <mat-label>Scheme ID</mat-label>
        <input type="input" matInput>
      </mat-form-field>

    </div>
    <div class="col-2">
      <mat-form-field>
        <mat-label>No of Units</mat-label>
        <input type="input" matInput>
      </mat-form-field>

    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Gross Weight</mat-label>
        <input type="input" matInput>
      </mat-form-field>

    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Total Value</mat-label>
        <input type="input" matInput>
      </mat-form-field>

    </div>

  </div>
  <div class="row border">
    <dx-data-grid #dataGrid id="gridContainer" keyExpr="ID" [showBorders]="true">
      <dxo-paging [enabled]="false"></dxo-paging>


      <dxi-column *ngFor="let data of schemecoloumnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
        [allowEditing]="true" alignment="center" backgroundcolor="#000">
      </dxi-column>
    </dx-data-grid>
  </div>
  <div class="row border mt-10">
    <dx-data-grid #dataGrid id="gridContainer" keyExpr="ID" [showBorders]="true">
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxi-column *ngFor="let data of productcoloumnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
        [allowEditing]="true" alignment="center" backgroundcolor="#000">
      </dxi-column>
    </dx-data-grid>
  </div>





  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Remarks</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-6">
      <div class="footer-btn">

        <button class="btn btn-primary">Save</button>&nbsp;
        <button class="btn btn-danger" (click)="close()">Cancel</button>&nbsp;
      </div>

    </div>
  </div>

</div>


