  <div class="container-fluid p-3 ">

    <!-- <mat-card >
    <mat-card-content> -->
    <div class="modal-header" style="padding: 10px 20px">
      <h4 class="modal-title" id="modal-dia-metal">
        Change Password
      </h4>
      <button type="button" *ngIf="this.showDialog" class="close" aria-label="Close" (click)="closeDialog()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="mat-card p-3 m-5 ">
    <!-- <div class="{{!this.showDialog ? 'mat-card': '' }} p-3 m-5 "> -->

      <!-- <div class="col-lg-6 ">
      <h4>Change Password</h4>
    </div> -->

      <!-- <div class="col-2">
            <mat-form-field>
              <mat-label>Division </mat-label>
              <input type="text" matInput formControlName="fcn_li_division" id="fcn_li_division" readonly />
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field>
              <mat-label>Item Code </mat-label>

            </mat-form-field>
          </div> -->
      <form [formGroup]="changePasswordForm">

        <div class="row p-1 m-t-10">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-md-4">
                <mat-form-field>
                  <mat-label>Username</mat-label>
                  <input type="text" matInput placeholder="Enter username" formControlName="username"
                    (focusout)="checkUserName($event)">
                  <mat-error *ngIf="changePasswordForm.controls.username.hasError('required')">
                    Username is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <mat-label>Password</mat-label>
                  <input matInput type="password" formControlName="password" placeholder="Enter password">
                  <mat-error *ngIf="changePasswordForm.controls.password.hasError('required')">
                    Password is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="changePasswordForm.controls.password.hasError('minlength')">
                    Password must be at least 3 characters long
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <mat-label>Confirm Password</mat-label>
                  <input matInput type="password" formControlName="confirmPassword"
                    placeholder="Enter confirm password">
                  <mat-error *ngIf="changePasswordForm.controls.confirmPassword.hasError('required')">
                    Confirm Password is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="changePasswordForm.controls.confirmPassword.hasError('minlength')">
                    Confirm Password must be at least 3 characters long
                  </mat-error>
                  <!-- <mat-error *ngIf="changePasswordForm.controls.confirmPassword.hasError('notSame')">
                    Passwords do not match
                  </mat-error> -->
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>

    <div class="text-right">
      <button class="btn btn btn-danger" *ngIf="this.showDialog" (click)="closeDialog()">
        Close</button>
         &nbsp;
      <button class="btn btn btn-primary" (click)="updatePassword()">
        Update</button>
    </div>
    <!-- </mat-card-content>
  </mat-card> -->
  </div>
