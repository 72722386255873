import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pos-net-sales-summary',
  templateUrl: './pos-net-sales-summary.component.html',
  styleUrls: ['./pos-net-sales-summary.component.scss']
})
export class PosNetSalesSummaryComponent implements OnInit {
  invoicebasic:any[];
  advanced:any[];
  @ViewChild('tabset')
  tabset: any;

  invoicebasiccolumnhead:any[] = ['Customer','Branch','Voucher','Division','Quantity','Amount','Profit'];
  advancedcolumnhead:any[] = ['Branch Code','Voc Type','Voc No','Voc Date','Amount FC','Balance FC'];

  constructor(
      private activeModal: NgbActiveModal
    ) {

    }

    ngOnInit(): void {
      this.tabset.tabs['advanced'].active = true
    }


    close() {
      this.activeModal.close();
    }

}
