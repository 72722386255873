import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-day-book',
  templateUrl: './day-book.component.html',
  styleUrls: ['./day-book.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DayBookComponent implements OnInit {
  columnhead:any[] = ['Voc No','Voc Date','Sman','Gross Amt','CC Comm. Amt','Net Amount','Sal Ret Amt','Sal Exc Amt','Credit Note','Discount','Adv. Ref#','Adv amount','Gift Redeem'];
  netcollectionhead:any[] = ['Mode','Received In','Description','Amount'];
  oldgoldhead:any[] = ['Stock Code','Quantity','Amount'];
  posaccounthead:any[] = ['Account','Description','Currency','Amount'];
  posaccountmovementhead:any[] = ['Account','Account Name','Voc Description','Voucher','Amount'];
  possalesordersummaryhead:any[] = ['Branch','Status','#Vocs','Amount','Gold & Oth','Dia & Oth'];
  salesmanwisesummaryhead:any[] = ['Salesman','#Docs','TotalAmount','Amount','Gold','Dia & Oth','Mkg. Value'];
  creditcashhead:any[] = ['Mode','Voctype','Receive As','Currency','Amount FC','Amount CC'];

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }
  close() {
    this.activeModal.close();
  }
}
