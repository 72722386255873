<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">Pending Sales Order</h5>
  <button type="button" class="btn btn-close" style="border-color: none;" (click)="close()"><i class="feather icon-x"
      style="font-size: medium;color:
      #FFFFFF;"></i></button>
</div>

<div class="modal-body orderlockunlock">
  <div class="row" style="margin-bottom: 10px;">

    <div class="col-3">
      <mat-form-field>
        <mat-label>Branch To</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Order No</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Customer</mat-label>
        <input type="input" matInput>
      </mat-form-field>
    </div>

    <div class="col-3">
      <button class="btn btn-primary detail-btn">Search</button>&nbsp;
    </div>
  </div>

  <div class="row" style="margin-bottom: 10px;">
    <div class="col-12">

      <dx-data-grid #dataGrid id="gridContainer" [dataSource]="tasks" keyExpr="ID" [showBorders]="true" >
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-editing mode="cell" [allowUpdating]="true">
        </dxo-editing>
        <dxo-selection mode="multiple"></dxo-selection>
        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <dxi-column *ngFor="let data of columnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
          [allowEditing]="true" alignment="center" backgroundcolor="#000" >
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>

  <div style="text-align: right; padding:10px;">
    <button class="btn btn-primary">Lock</button>&nbsp;
    <button class="btn btn-danger" (click)="close()">Close</button>&nbsp;
  </div>


</div>
