<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">Scheme Registration</h5>
  <button type="button" class="btn btn-close" style="border-color: none;" (click)="close()"><i class="feather icon-x"
      style="font-size: medium;color:
      #FFFFFF;"></i></button>
</div>
<div class="modal-body orderlockunlock" style="padding: 8px;padding-top: 8px;">

  <div class="container">
    <nav data-role="ribbonmenu">
      <ul class="tabs-holder">
        <li><a href="#section-group">Header Details</a></li>
      </ul>

      <div class="content-holder">
        <div class="section" id="section-group">
          <div class="group">
            <div data-role="button-group" data-cls-active="active">
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-file" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">New</span>
              </button>
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa fa-file-pen" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Edit</span>
              </button>
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-file-circle-minus" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Delete</span>
              </button>
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-rotate" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Refresh</span>
              </button>
            </div>
            <span class="title">File</span>
          </div>
          <div class="group">
            <div data-role="button-group" data-cls-active="active">
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-magnifying-glass" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Search</span>
              </button>
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-backward-step" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">First</span>
              </button>
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-caret-left" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Previous</span>
              </button>
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-caret-right" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Next</span>
              </button>
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-forward-step" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Last</span>
              </button>
            </div>
            <span class="title">Search</span>
          </div>
          <div class="group">
            <div data-role="button-group" data-cls-active="active">

              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa fa-print" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Print</span>
              </button>
            </div>
            <span class="title">Print</span>
          </div>
          <div class="group">
            <div data-role="button-group" data-cls-active="active">

              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-circle-check" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Audit Trial</span>
              </button>
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-file-export" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Post</span>
              </button>
            </div>

            <span class="title">Accounts</span>
          </div>
          <div class="group">
            <div data-role="button-group" data-cls-active="active">

              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-envelopes-bulk" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Atachment</span>
              </button>
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa-solid fa-file-lines" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Log</span>
              </button>
            </div>

            <span class="title">Log</span>
          </div>
          <div class="group">
            <div data-role="button-group" data-cls-active="active">
              <button class="ribbon-button">
                <span class="icon">
                  <i class="fa fa-x" style="color: red;"></i>
                </span>
                <span class="caption">Exit</span>
              </button>
            </div>
            <span class="title">Exit</span>
          </div>
        </div>
      </div>
    </nav>

    <div class="container" style="background-color: #ffff;">

      <div class="details">

        <div class="row finput" style="border: solid 0.5px;border-color: #e1dddd;">
          <div class="col" style="padding-top:10px;">
            <div class="form">
              <mat-form-field style="width: 50%;">
                <mat-label>Code</mat-label>
                <input type="input" matInput>
              </mat-form-field>
              <mat-form-field style="padding-left: 2%;width: 40%;">
                <mat-label>Scheme ID</mat-label>
                <input type="input" matInput>
              </mat-form-field>


            </div>
            <div class="form">
              <mat-form-field style="width: 50%;">
                <mat-label>Name</mat-label>
                <input type="input" matInput>
              </mat-form-field>
              <mat-form-field style="padding-left: 2%;width: 40%;">
                <mat-label>Email</mat-label>
                <input type="input" matInput>
              </mat-form-field>



            </div>

            <div class="form">
              <mat-form-field style="width: 50%;">
                <mat-label>Mobile Number</mat-label>
                <input type="input" matInput>
              </mat-form-field>

              <mat-form-field style="padding-left: 2%;width: 40%;">
                <mat-label>Party Address</mat-label>
                <textarea matInput></textarea>
              </mat-form-field>

            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="tool-bar">
    <button type="button" class="btn btn-outline-secondary"><i class="feather icon-plus"
        style="font-size: 15px; color: green;"></i> </button>
    <button type="button" class="btn btn-outline-secondary" style="margin-left: 10px;"><i class="feather icon-x"
        style="font-size: 15px; color: red;"></i> </button>

  </div>
  <div class="container" style="padding-top: 5px;">
    <div class="details">


      <div id="data-grid-demo" class="consigmentstock">

        <dx-data-grid id="gridContainer" [showBorders]="true" (onSelectionChanged)="selectionChanged($event)">
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-editing mode="cell" [allowUpdating]="true">
          </dxo-editing>

          <dxi-column *ngFor="let data of consigmentstockhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
            [allowEditing]="true" alignment="center">
          </dxi-column>

        </dx-data-grid>
      </div>
    </div>
  </div>



  <div class="modal-footer" style="padding: 5px 10px">
    <div style="padding-bottom: 0px;">
      <div style="text-align: right; padding:10px; ">

        <button class="btn btn-primary">Save</button>&nbsp;
        <button class="btn btn-danger" (click)="close()">Cancel</button>

      </div>
    </div>
  </div>
</div>
