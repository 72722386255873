<div class="row mb-3">
  <div class="col-sm-12 border-radius-bltl border-radius-brtr" style="background:#eee;">
    <h5>Demo Modal</h5>
  </div>
</div>

<div class="row p-2 row-grid">
  <div>
    <button class="btn btn-primary" (click)="openestimationlist()">Estimation List</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="opendiscountauth()">Discount Authorization</button>
  </div>
  <div>
    <button class="btn btn-primary " (click)="openconsignmentstock()">Consignment Stock</button>
  </div>
  <div>
    <button class="btn btn-primary " (click)="openconsignmentitemdetail()">Consignment Item
      Detail</button>
  </div>
  <div>
    <button class="btn btn-primary " (click)="openordercancel()">Order Cancellation</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openlockunlock()">Order Lock & Unlock</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openschemeregisteration()">Scheme Registration</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openschemeregisterdetail()">Scheme Registration Details</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openposaction()">POS Action</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openPosComplaint()">POS Complaint</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openpendingsalesorder()">Pending Sales Order</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="opencustomerfeedback()">Customer Feedback</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="opencurrencyreceiptregister()">Currency Receipt Register</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="opendiscountdistribute()">Discount Distribute</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="opencustomersearch()">Customer Search</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openpcrselection()">Pcr Selection</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openposfeedback()">Pos Feedback</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openpossalesmancommission()">Pos Salesman Commission</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openpositemsplit()">Pos Item Split</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openpossalescommissionlist()">Pos Sales Commission List</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openschememaster()">Scheme Master</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="opencustomerwalkin()">Customer Walkin</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="opennetsalessummary()">Privilage Net Sales Summary</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="opendaybook()">Day Book</button>
  </div>
  <div>
    <button class="btn btn-primary" (click)="openpossalesdetail()">POS SalesDiaDetails</button>
  </div>
</div>



<!--Estimation Modal start-->
<ng-template #estimationmodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

  <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e;">
    <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;">Sales Estimation</h5>
    <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i
        class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
  </div>
  <div class="modal-body" style="padding: 0px;">
    <div class="cds-billing">
      <div class="content-holder">
        <div class="row">
          <div class="col-12">
            <app-estimation-list></app-estimation-list>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>
<!--Estimation Modal End-->



<!--Consignment-item-detail Modal start-->
<ng-template #consignmentitemdetailmodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

  <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e;">
    <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;font-size: medium;">Consignment Item Detail</h5>
    <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i
        class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
  </div>
  <div class="modal-body" style="padding: 0px;">
    <div class="cds-billing">
      <div class="content-holder">
        <div class="row">
          <div class="col-12">
            <app-consignment-item-detail></app-consignment-item-detail>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>
<!--Consignment-item-detail Modal End-->




<!--Scheme Register-detail Modal start-->
<ng-template #schemeregisterdetailmodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

  <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e;">
    <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;font-size: medium;">Scheme Registration Detail
    </h5>
    <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i
        class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
  </div>
  <div class="modal-body" style="padding: 0px;">
    <div class="cds-billing">
      <div class="content-holder">
        <div class="row">
          <div class="col-12">
            <app-scheme-registration-details></app-scheme-registration-details>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>
<!--Scheme Register-detail Modal End-->
