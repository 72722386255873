import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pos-discount-distribute',
  templateUrl: './pos-discount-distribute.component.html',
  styleUrls: ['./pos-discount-distribute.component.scss']
})
export class PosDiscountDistributeComponent implements OnInit {
  columnhead:any[] = ['Division','Invoice Amt','Applicable To','Disc %','Disc Amt','Net Amt','Total Amt'];
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }
  close() {
    this.activeModal.close();
  }
}
