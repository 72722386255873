import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosActionComponent } from './pos-action/pos-action.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { PosComplaintComponent } from './pos-complaint/pos-complaint.component';
import { PendingSalesOrderComponent } from './pending-sales-order/pending-sales-order.component';
import { DxDataGridModule } from 'devextreme-angular';
import { MatRadioModule } from '@angular/material/radio';
import { DxCheckBoxModule } from 'devextreme-angular';
import { CurrencyReceiptRegisterComponent } from './currency-receipt-register/currency-receipt-register.component';
import { PosCustomerSearchComponent } from './pos-customer-search/pos-customer-search.component';
import { PosDiscountDistributeComponent } from './pos-discount-distribute/pos-discount-distribute.component';
import { OldGoldPurchaseComponent } from './old-gold-purchase/old-gold-purchase.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PurchaseDiractetailComponent } from './purchase-diractetail/purchase-diractetail.component';
import { PosPurchaseDirectComponent } from './pos-purchase-direct/pos-purchase-direct.component';

import { PosSalesCommissionComponent } from './pos-sales-commission/pos-sales-commission.component';
import { PosFeedbackComponent } from './pos-feedback/pos-feedback.component';
import { PosPcrSelectionComponent } from './pos-pcr-selection/pos-pcr-selection.component';
import { PosSalesmanCommissionListComponent } from './pos-salesman-commission-list/pos-salesman-commission-list.component';
import { PosWalkinCustomerComponent } from './pos-walkin-customer/pos-walkin-customer.component';
import { PosSchemeMasterComponent } from './pos-scheme-master/pos-scheme-master.component';
import { PosItemSplitComponent } from './pos-item-split/pos-item-split.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PosNetSalesSummaryComponent } from './pos-net-sales-summary/pos-net-sales-summary.component';
import { DayBookComponent } from './day-book/day-book.component';
import { PosSalesDiaDetailGstComponent } from './pos-sales-dia-detail-gst/pos-sales-dia-detail-gst.component';
import {MatTableModule} from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    PosActionComponent,
    PosComplaintComponent,
    PendingSalesOrderComponent,
    CurrencyReceiptRegisterComponent,
    PosCustomerSearchComponent,
    PosDiscountDistributeComponent,
    OldGoldPurchaseComponent,
    PurchaseDiractetailComponent,
    PosPurchaseDirectComponent,
    PosSalesCommissionComponent,
    PosFeedbackComponent,
    PosPcrSelectionComponent,
    PosSalesmanCommissionListComponent,
    PosWalkinCustomerComponent,
    PosSchemeMasterComponent,
    PosItemSplitComponent,
    PosNetSalesSummaryComponent,
    DayBookComponent,
    PosSalesDiaDetailGstComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DxDataGridModule,
    MatRadioModule,
    DxCheckBoxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatCheckboxModule,
    NgbModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,

  ]
})
export class PosActionModule { }
