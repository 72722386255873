<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">POS Complaint</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i class="feather icon-x"></i></button>
</div>
<div class="modal-body poscomplaint">
  <div class="container">

    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Staff Courtesy</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>Product Knowledge Of our staff</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Location and ambience of shop</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>Variety and quality of jewellery</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Overall Experience</mat-label>
          <input type="input" matInput>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>Additional Comments</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="container completion">
    <div class="row  justify-center">
      <div class="col-4  image-container">

      </div>
    </div>
    <div class="row justify-center">
      <div class=" col-4 img-container-footer">
        <span class="icon">
          <i class="fa fa-camera"></i>
        </span>
        <span class="icon">
          <i class="fa fa-file-image"></i>
        </span>

      </div>
    </div>

  </div>

  <div class="footer-btn">
    <button class="btn btn-primary">Save</button>&nbsp;
    <button class="btn btn-danger" (click)="close()">Cancel</button>&nbsp;
  </div>
</div>
