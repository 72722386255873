<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">Currency Receipt Register (POS)</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i class="feather icon-x" ></i></button>
</div>
<div class="modal-body ">
  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-label>Branch</mat-label>
        <mat-select>
          <mat-option value="one">Branch 1</mat-option>
          <mat-option value="two">Branch 2</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <mat-form-field>
            <mat-label>Date From</mat-label>
            <input matInput [matDatepicker]="datefrom">
            <mat-datepicker-toggle matSuffix [for]="datefrom"></mat-datepicker-toggle>
            <mat-datepicker #datefrom></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field>
            <mat-label>Date To</mat-label>
            <input matInput [matDatepicker]="dateto">
            <mat-datepicker-toggle matSuffix [for]="dateto"></mat-datepicker-toggle>
            <mat-datepicker #dateto></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field>
        <mat-label>Show</mat-label>
        <mat-select>
          <mat-option value="one">option-1</mat-option>
          <mat-option value="two">option-2</mat-option>
        </mat-select>
      </mat-form-field>
      </div>
  </div>

  <div class="row">
    <div class="col-4">
      <mat-form-field>
            <mat-label>Salesman From</mat-label>
            <input matInput >
        </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field>
            <mat-label>Salesman To</mat-label>
            <input matInput>
        </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field>
        <mat-label>Report To</mat-label>
        <mat-select>
          <mat-option value="one">option-1</mat-option>
          <mat-option value="two">option-2</mat-option>
        </mat-select>
      </mat-form-field>
      </div>
  </div>

  <div class="footer-btn">
    <button class="btn btn-primary">Go</button>&nbsp;
    <button class="btn btn-danger" (click)="close()">Cancel</button>&nbsp;
  </div>
</div>
