<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">Customer Feedback</h5>
  <button type="button" class="btn btn-close" style="border-color: none;" (click)="close()"><i class="feather icon-x"
      style="font-size: medium;color:
      #FFFFFF;"></i></button>
</div>

<div class="modal-body orderlockunlock">
  <div class="row" style="margin-bottom: 10px;">
    <div class="col-3">
      <mat-form-field>
        <mat-label>Date</mat-label>
        <input type="date" matInput>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select>
          <mat-option value="one">COD</mat-option>
          <mat-option value="two">CASH</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Code</mat-label>
        <mat-icon matSuffix class="srchicon">search</mat-icon>
            <input matInput type="search" placeholder="search here">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input type="input" matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Ref.No</mat-label>
      <input type="input" matInput>
    </mat-form-field>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Sales Man</mat-label>
            <mat-icon matSuffix class="srchicon">search</mat-icon>
                <input matInput type="search" placeholder="search here">
        </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Complaint Category</mat-label>
            <input type="input" matInput>
          </mat-form-field>
        </div>
      </div>
      <mat-form-field>
        <mat-label>Mobile</mat-label>
        <input type="input" matInput>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input type="input" matInput>
      </mat-form-field>
      <div class="row">
        <div class="col-3">

          <mat-checkbox>Show All</mat-checkbox>

        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>From Date</mat-label>
            <input type="date" matInput>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>To Date</mat-label>
            <input type="date" matInput>
          </mat-form-field>
        </div>
        <div class="col-3">
          <button class="btn btn-primary">Apply</button>&nbsp;
        </div>
      </div>
    </div>

    <div class="col-3">
      <div class="col-xl-12 col-md-6">
        <table class="m-t-10 rhs-table-1">
            <tr>
                <td class="td-w-40">Voucher</td>
                <td><input type="text" class="form-control textboxcorner" id=""  />
                </td>
            </tr>
            <tr>
                <td class="td-w-40">Date</td>
                <td><input type="text" class="form-control textboxcorner" id=""  />
                </td>
            </tr>
            <tr>
                <td class="td-w-40">Branch Location</td>
                <td><input type="text" class="form-control textboxcorner" id="" />
                </td>
            </tr>
            <tr>
              <td class="td-w-40">Amount</td>
              <td><input type="text" class="form-control textboxcorner" id=""  />
              </td>
          </tr>
          <tr>
            <td class="td-w-40">Total Sales</td>
            <td><input type="text" class="form-control textboxcorner" id=""  />
            </td>
        </tr>
            </table>
            </div>

          </div>
  </div>
  <div class="row" style="margin-bottom: 10px;">
<div class="col-3">
  <button class="btn btn-primary" style="width: 100%;">Feedback</button>&nbsp;
</div>
<div class="col-3">
  <button class="btn btn-primary" style="width: 100%;">Attachment</button>&nbsp;
</div>
<div class="col-5" style="text-align: right;">

  <button class="btn btn-danger" (click)="close()">Close</button>&nbsp;

</div>

  </div>



  <ngb-tabset [destroyOnHide]="false">

    <ngb-tab title="Complaint">
        <ng-template ngbTabContent>
          <div class="row" style="margin-bottom: 10px;">
            <div class="col-12">

              <dx-data-grid #dataGrid id="gridContainer" [dataSource]="complaint" keyExpr="ID" [showBorders]="true" >
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxo-editing mode="cell" [allowUpdating]="true">
                </dxo-editing>
                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-scrolling mode="virtual"></dxo-scrolling>

                <dxi-column *ngFor="let data of complaintcolumnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
                  [allowEditing]="true" alignment="center" backgroundcolor="#000" >
                </dxi-column>
              </dx-data-grid>
            </div>
          </div>

        </ng-template>
    </ngb-tab>

    <ngb-tab>
        <ng-template ngbTabTitle>Feedback</ng-template>
        <ng-template ngbTabContent>
          <div class="row" style="margin-bottom: 10px;">
            <div class="col-12">

              <dx-data-grid #dataGrid id="gridContainer" [dataSource]="complaint" keyExpr="ID" [showBorders]="true" >
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxo-editing mode="cell" [allowUpdating]="true">
                </dxo-editing>
                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-scrolling mode="virtual"></dxo-scrolling>

                <dxi-column *ngFor="let data of feedbackcolumnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
                  [allowEditing]="true" alignment="center" backgroundcolor="#000" >
                </dxi-column>
              </dx-data-grid>
            </div>
          </div>
        </ng-template>
    </ngb-tab>
    <ngb-tab>
      <ng-template ngbTabTitle>Complaint</ng-template>
      <ng-template ngbTabContent>
        <div class="row" style="margin-bottom: 10px;">
          <div class="col-12">

            <dx-data-grid #dataGrid id="gridContainer" [dataSource]="grpcomplaint" keyExpr="ID" [showBorders]="true" >
              <dxo-paging [enabled]="false"></dxo-paging>
              <dxo-editing mode="cell" [allowUpdating]="true">
              </dxo-editing>
              <dxo-selection mode="multiple"></dxo-selection>
              <dxo-scrolling mode="virtual"></dxo-scrolling>

              <dxi-column *ngFor="let data of grpcomplaintcoloumnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
                [allowEditing]="true" alignment="center" backgroundcolor="#000" >
              </dxi-column>
            </dx-data-grid>
          </div>
        </div>
      </ng-template>
  </ngb-tab>
  <ngb-tab>
    <ng-template ngbTabTitle>Feedback</ng-template>
    <ng-template ngbTabContent>
      <div class="row" style="margin-bottom: 10px;">
        <div class="col-12">

          <dx-data-grid #dataGrid id="gridContainer" [dataSource]="grpfeedback" keyExpr="ID" [showBorders]="true" >
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-editing mode="cell" [allowUpdating]="true">
            </dxo-editing>
            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-scrolling mode="virtual"></dxo-scrolling>

            <dxi-column *ngFor="let data of grpfeedbackcoloumnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
              [allowEditing]="true" alignment="center" backgroundcolor="#000" >
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </ng-template>
</ngb-tab>
</ngb-tabset>
<ngb-tabset [destroyOnHide]="false">

  <ngb-tab title="Action">
      <ng-template ngbTabContent>
        <div class="row" style="margin-bottom: 10px;">
          <div class="col-12">

            <dx-data-grid #dataGrid id="gridContainer" [dataSource]="actionarea" keyExpr="ID" [showBorders]="true" >
              <dxo-paging [enabled]="false"></dxo-paging>
              <dxo-editing mode="cell" [allowUpdating]="true">
              </dxo-editing>
              <dxo-selection mode="multiple"></dxo-selection>
              <dxo-scrolling mode="virtual"></dxo-scrolling>

              <dxi-column *ngFor="let data of actionareacoloumnhead" dataField="{{data}}" [width]="auto" caption="{{data}}"
                [allowEditing]="true" alignment="center" backgroundcolor="#000" >
              </dxi-column>
            </dx-data-grid>
          </div>
        </div>
      </ng-template>
  </ngb-tab>
</ngb-tabset>



  <div style="text-align: right; padding:10px;">
    <!-- <button class="btn btn-primary">Lock</button>&nbsp; -->
    <button class="btn btn-danger" (click)="close()">Close</button>&nbsp;
  </div>


</div>
