import { DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

import { SuntechapiService } from 'src/app/suntechapi.service';


@Injectable({
  providedIn: 'root'
})
export class CommonfuncService {

  public allMessageBoxData: any;
  public allCompanyParams: any;
  public baseUsername: any;
  public baseUserbranch: any;
  public baseYear: any;
  public allbranchMaster: any = JSON.parse(localStorage.getItem('branchdetails'));
  public allBranchCurrency: any;
  public currencyRate: any;
  public divisionMasterList: any;
  public mastersList: any = [];
  public comboFilter: any = [];

  amtFormat: any;
  mQtyFormat: any;
  amtDecimals: any;
  mQtyDecimals: any;
  compCurrency: any;

  basePartyCode: any;
  posKARATRATECHANGE: any;

  constructor(
    private snackBar: MatSnackBar,
    private suntechApi: SuntechapiService,
    private _decimalPipe: DecimalPipe,
    private datePipe: DatePipe

  ) {
    this.baseUsername = localStorage.getItem('username');
    this.baseYear = localStorage.getItem('year');
  }

  getDivisionMS(division) {
    return this.divisionMasterList.filter((data) => data.DIVISION_CODE == division)[0].DIVISION;
  }
  Null2BitValue(value) {
    value = value.trim();
    if (value == null || value.toString() == '' || value.toString().toUpperCase().trim() == "FALSE" || value.toString() == "0") {
      return false;
    }
    else {
      return true;
    }
  }
  emptyToZero(value) {
    value = typeof (value) == 'number' || value == undefined ? value : value.trim();

    if (value == null || value.toString() == '' || value == undefined || value == 'NaN') {
      return 0;
    }
    else {
      return parseFloat(value);
      // return value;
    }
  }

  enforceMinMax(el) {
    console.log(["e", "E"].includes(el.value), el.value, typeof el.value);
    // console.log('min ', typeof el.min, el.min, 'max ', typeof el.max, el.max, 'max length', typeof el.maxLength, el.maxLength);
    // ["e", "E"].includes(el.value) && el.preventDefault();
    // alert(el.max)
    // alert(el.value)
    // if(["e", "E"].includes(el.value)){
    // // if(["e", "E"].includes(el.value)){
    //   alert('true');
    //   // el.value = el.value.slice(el.val.toString().length, 1);
    // }

    if (el.value != '') {

      if (parseFloat(el.value) < parseFloat(el.min) && el.min != '') {
        el.value = el.min;
      }
      if (
        parseFloat(el.value) >
        parseFloat(el.max) && el.max != ''
      ) {
        el.value = el.max;
      }
      if (el.value.length > el.maxLength && el.maxLength != -1) {
        el.value = el.value.slice(0, el.maxLength);
      }
    } else {
      // el.value = el.value.slice(el.val.toString().length, 1);
    }
    return true;
  }

  stringToBoolean = (string) => string != undefined ? string.toString().toLowerCase() == 'false' ? false : true : false;


  formControlSetReadOnly(id: any, isReadonly: boolean) {
    const ele: any = document.getElementById(id);
    if(ele != null)
    ele.readOnly = isReadonly;
    // (<any>formControl).nativeElement.readonly = isReadonly;
  }
  formControlSetReadOnlyByClass(className: any, isReadonly: boolean) {
    const ele: any = document.getElementsByClassName(className);
    for (let i = 0; i < ele.length; i++) {
      ele[i].readOnly = isReadonly;
    }
  }

  GeneralMasterCodeValidate(txtCode, strType) {
    txtCode = isNaN(txtCode) ? txtCode.trim() : txtCode;
    strType = isNaN(strType) ? strType.trim() : strType;
    try {
      this.suntechApi.generalMasterCodeValidate(strType.trim()).subscribe((data) => {
        console.log(data);
      });
    }
    catch (error) {
      this.snackBar.open(error.message.toString(), 'OK');
    }
  }

  getCurrRate(currency) {
    // alert(currency);
    const result = this.allBranchCurrency.filter(data => data.CURRENCY_CODE == currency);
    // this.currencyRate = result[0].CONV_RATE;
    // alert( this.currencyRate )
    // console.log('=============currencyRate=======================');
    // console.log(this.allBranchCurrency);
    // console.log(result);
    // console.log(currency);
    // console.log( this.currencyRate);
    // console.log('====================================');
    // alert('rate ' +result.CONV_RATE);
    return result[0].CONV_RATE;
  }
  // CCToFC(currency, rate, amount) {
  CCToFC(currency, amount) {

    try {
      let rate = this.getCurrRate(currency);
      console.log('====================================');
      console.log(currency, amount);
      console.log('====================================');
      currency = currency;
      console.log('=================Before Rate===================');
      console.log(rate, amount);
      console.log('====================================');
      rate = typeof (rate) == 'number' ? rate : rate;
      amount = typeof (amount) == 'number' ? amount : amount;
      // rate = typeof (rate) == 'number' ? rate : rate.trim();
      // amount = typeof (amount) == 'number' ? amount : amount.trim();

      // if (this.allCompanyParams.COMPANYCURRENCY == currency) {
      //   return amount;
      // }
      // else {
      // currency = 'AED';
      console.log('=================Rate===================');
      console.log(rate, amount);
      console.log('====================================');
      let convertedAmount = 0;

      const result = this.allBranchCurrency.filter(data => data.CURRENCY_CODE == currency);
      if (result.MUL_DIV == 'M') {
        convertedAmount = amount / rate;
        console.log('.MUL_DIV == m', convertedAmount);
        return convertedAmount;
      } else {
        convertedAmount = amount * rate;
        console.log('.MUL_DIV == D', convertedAmount);
        return convertedAmount;
      }
    }
    // }
    catch (error) {
      this.snackBar.open(error.message.toString(), 'OK');
    }
  }

  FCToCC(currency, amount) {
    let rate = this.getCurrRate(currency);
    try {
      currency = currency;
      rate = typeof (rate) == 'number' ? rate : rate;
      amount = typeof (amount) == 'number' ? amount : amount;
      // currency = currency.trim();
      // rate = typeof (rate) == 'number' ? rate : rate.trim();
      // amount = typeof (amount) == 'number' ? amount : amount.trim();

      // if (this.allCompanyParams.COMPANYCURRENCY == currency) {
      //   return amount;
      // }
      // else {
      // currency = 'AED';

      let convertedAmount = 0;

      const result = this.allBranchCurrency.filter(data => data.CURRENCY_CODE == currency);
      if (result.MUL_DIV == 'M') {
        convertedAmount = amount / rate;

        return this.transformDecimalVB(this.amtDecimals, convertedAmount);
      } else {
        convertedAmount = amount * rate;
        return this.transformDecimalVB(this.amtDecimals, convertedAmount);
      }
    }
    // }
    catch (error) {
      this.snackBar.open(error.message.toString(), 'OK');
    }
  }

  // Transform number to decimal
  transformDecimalVB(format, num) {
    // alert((42385.6075).toFixed(1))
    // alert(`${num} - ${parseFloat(num).toFixed(format)}`);

    const formatVal = `1.${format}-${format}`;
    // console.log('formatVal',formatVal, 'num',num);
    var val: any = this._decimalPipe.transform(num || 0, formatVal);
    // console.log(val);
    val = val.includes(',') ? val.replaceAll(',', '') : val;
    // console.log(val);

    // alert(this._decimalPipe.transform(num, formatVal));
    return parseFloat(val).toFixed(format);
    // return parseFloat(num).toFixed(format);
    // return parseFloat( parseFloat(num).toFixed(format));
  }

  generateNumber() {
    return Math.floor(1000 + Math.random() * 9000)
  }

  // Get Messages by id
  getMsgByID(id) {
    id = id.trim();
    const res = this.allMessageBoxData?.filter((data) => data.MSG_ID == id)
    if (res != null)
      return res[0].MSG_ENGLISH;
    else
      return '';
  }

  // Get Master by id
  getMasterByID(id) {
    id = id.trim();
    const res = this.mastersList.filter((data) => data.TYPES == id)
    return res;
  }

  // Get Combo filter(selectbox) data by id
  getComboFilterByID(type) {
    type = type.trim();
    const res = this.comboFilter.filter((data) => data.COMBO_TYPE.toString().toLowerCase() == type.toString().toLowerCase())
    return res;
  }

  // convert date to mm-dd-yyy format
  convertDateToMDY(data) {
    if (data != '' && data != null) {
      const date = new Date(data);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
      return formattedDate;
    } else {
      return '';
    }

  }

  dateFilter = (d: Date | null): boolean => {
    console.log(d)
    const date = (d || new Date()).toLocaleDateString();
    return /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date);
  }

  nullOrEmpty(value, alternate) {
    return (value != null && value != '') ? value : alternate;
  }

  cDateFormat(value) {
    if (value != '' && value != null) {
      // const localDate = new Date(value.getTime() - (value.getTimezoneOffset() * 60000));
      // let formattedDate = this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss');
      let formattedDate = value;
      console.log(typeof value );
      if (typeof value != 'object') {
      // if (value.toString().includes('T')) {
        console.log('=============cDateFormat=======================');
        console.log(formattedDate);
        formattedDate = formattedDate.toString().split('T')[0] + 'T00:00:00';
        console.log(formattedDate);
        console.log('====================================');
      } else {
        // formattedDate.toISOString().split('T')[0]+'T00:00:00';
        // const momentObj = moment(value);
        formattedDate = this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss');

        console.log(formattedDate);
        console.log('====================================');
      }

      return formattedDate;
    } else {
      return '';
    }
  }

  custDateFormat(value) {
    return this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss');
  }

  noEmptySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.trim() === '') {
        return { 'noEmptySpaces': true };
      }
      return null;
    };
  }
}
