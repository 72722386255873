import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosCustomerEnquiryComponent } from './pos-customer-enquiry/pos-customer-enquiry.component';
import { DxDataGridModule } from 'devextreme-angular';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';


@NgModule({
  declarations: [
    PosCustomerEnquiryComponent
  ],
  imports: [
    CommonModule,DxDataGridModule,
    MatTabsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class PosCustomerEnquiryModule { }
