import { ApiConfigService } from './apiconfig.service';
// import { ILeftMenus } from './../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { ConfigService } from './config.service';
import { switchMap } from 'rxjs/operators';

// const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class SuntechapiService {

  baseUrl: String;
  public httpOptions = {
    headers: new HttpHeaders({}),
  };


  public leftmenus: any = [];
  // public leftmenus: ILeftMenus[] = [{
  //   BUTTONID: '1',
  //   BUTTON_VISIBLE: 'True',
  //   ButtonDescription: '&Master ',
  //   ButtonDescriptionCHINESE: '',
  //   ButtonDescriptionJOR: '',
  //   ButtonDescriptionOther: '&Master ',
  //   ButtonDescriptionSAU: '',
  //   ButtonDescriptionTURKISH: '',
  //   ButtonImage: '',
  //   ButtonImagePath: '',
  //   DetailHolder: '1',
  //   DisplayText: '&Master ',
  //   DisplayTextOther: '&Master ',
  //   MENU_CAPTION_ENG: '&Master ',
  //   MENU_DESC_ARABIC_JOR: '',
  //   MENU_DESC_ARABIC_SAU: '',
  //   MENU_ID: '00001',
  //   menuurl: 'master'
  // }];

  rightMenuAllValues: any = [];

  // private dataSource: BehaviorSubject<any> = new BehaviorSubject<any>({'key':[1,2,3]});
  // data: Observable<any> = this.dataSource.asObservable();

  companyParamSubject: Subject<any>;
  constructor(private http: HttpClient,
    private configService: ConfigService,
    // private apiConfigService: ApiConfigService
  ) {

    // this.getConfigUrl();

    this.companyParamSubject = new Subject<any>();

    console.log('suntechapi service call ...');
    this.httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': 'somekeytokenwillcomehere',
      }),
    };

    if (this.rightMenuAllValues != '')
      this.setRightMenuAll();
  }

  // async getConfigUrl() {
  //   await this.configService.loadConfig();
  //   apiUrl = await this.configService.getConfig();
  //   // console.log('====================================');
  //   // console.log(apiUrl);
  //   // console.log('====================================');
  // }

  set setLeftMenuItems(val) {
    this.leftmenus = val;
    localStorage.setItem('leftmenus', JSON.stringify(this.leftmenus));
    // console.log(this.rightMenuAllValues);
    if (this.rightMenuAllValues != null)
      this.setRightMenuAll();

  }

  get getLeftMenuUItems() {
    return this.leftmenus;
  }

  setRightMenuAll() {
    console.log('------------------setRightMenuAll fun call---------------------');

    const branchCode = localStorage.getItem('userbranch');
    const userName = localStorage.getItem('username')

    this.leftmenus.map(data => {
      this.getMenusPage(branchCode, userName, data?.BUTTONID, data?.MENU_ID).subscribe((res => {
        localStorage.setItem('rightMenuId_' + data.BUTTONID, JSON.stringify(res));
        this.rightMenuAllValues.push({ 'rightMenuId': data.BUTTONID, res });
        return res;
      }
      ));
    });
    console.log(this.rightMenuAllValues);

    return this.rightMenuAllValues;
  }

  get getRightMenuAll() {
    return this.setRightMenuAll();
  }

  /* Sales Order Services */


  setCompanyParamSubject(data) {
    console.log('setCompanyParamSubject');
    this.companyParamSubject.next(data);
  }

  getBranchMaster(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'BranchMaster');
      })
    );
  }

  getSalesPersonMaster(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'VBEstimation/VBSalespersonMaster');
      })
    );
  }

  getOrderType(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'GeneralMaster?Types=ORDERTYPE%20MASTER');
      })
    );
  }

  getCustomers(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl + 'AccountMaster?AccountMode=P,R&fields=ACCODE,ACCOUNT_HEAD'
        );
      })
    );
  }

  getCustomerDetails(acCode): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'AccountMaster?strAccode=' + acCode);

      })
    );
  }

  getAllColors(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'GeneralMaster?Types=COLOR%20MASTER');

      })
    );
  }

  getAllShapes(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'GeneralMaster?Types=SHAPE%20MASTER');
      })
    );
  }

  getAllSize(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'GeneralMaster?Types=SIZE%20MASTER');

      })
    );
  }

  getAllClarity(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'GeneralMaster?Types=CLARITY%20MASTER');
      })
    );

  }

  getEnamelColors(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'GeneralMaster?Types=ENAMEL%20COLOR');

      })
    );
  }

  getKarats(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'karatMaster');

      })
    );
  }

  getDesignProperties(strDesignCode = 'MB002293'): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'SalesOrder?strDesignCode=' +
          strDesignCode +
          '&strIsDesignStock=N&strIsComponent=Y&strIsColorSet=N&strIsMetalColor=%22%22'
        );
      })
    );
  }

  getSalesOrderStock(catCode = '000'): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl + 'SalesOrderStockMain?strCatalogueCode=' + catCode
        );
      })
    );
  }

  getCatalogueList(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'CatalogueLookup');

      })
    );
  }

  getSalesOrderDetails(orderno, vocno, voctype, bcode, year): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'SalesOrder?BRANCH_CODE=' + bcode + '&VOCTYPE=' + voctype + '&VOCNO=' + vocno + '&YEARMONTH=' + year + '&SalesOrderNo=' + orderno + '');
      })
    );
  }

  getProductDetailsbyStockCode(stockCode = 'MR011317'): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl + 'SalesOrderStockMain?strStockCode=' + stockCode
        );

      })
    );
  }

  /*getProducts_SalesOrderEdit(sessionId = ""): Observable<any> {
    return this.http.get(apiUrl+'SalesOrderEdit?SESSION_ID='+sessionId);
  }*/

  getProducts_SalesOrderEdit(sessionId = ''): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl + 'SalesOrderEdit/GetsaleorderTemp?SESSION_ID=' + sessionId
        );

      })
    );

  }

  getProducts_SalesOrderEditComponents(sessionId = ''): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl + 'SalesOrderEditComponents?strsessionId=' + sessionId
        );
      })
    );
  }

  getProducts_SalesOrderEditComponents_by_designCode(
    sessionId = '',
    designCode = ''
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'SalesOrderEditComponents?strsessionId=' +
          sessionId +
          '&strDesignCode=' +
          designCode
        );
      })
    );

  }

  /*getProducts_SalesOrderEditComponents_by_designCode_unq_design_id(sessionId = "", designCode="", strUNQ_DESIGN_ID=""): Observable<any> {
    return this.http.get(apiUrl+'SalesOrderEditComponents?strsessionId='+sessionId+"&strDesignCode="+designCode+"&strUNQ_DESIGN_ID="+strUNQ_DESIGN_ID);
  } */

  getProducts_SalesOrderEditComponents_by_designCode_unq_design_id(
    sessionId = '',
    designCode = '',
    strUNQ_DESIGN_ID = ''
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(
          apiUrl +
          'SalesOrderEditComponents/GetsalesOrderStmtlDetailsTemp?strsessionId=' +
          sessionId +
          '&strDesignCode=' +
          designCode +
          '&strUNQ_DESIGN_ID=' +
          strUNQ_DESIGN_ID
        );
      })
    );
  }

  get_SalesOrderEdit_UID(sessionId, strStockCode) {

    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'SalesOrderEdit?SESSION_ID=' +
          sessionId +
          '&strStockCode=' +
          strStockCode
        );
      })
    );
  }

  getAllSalesOrders(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'SalesOrder');
      })
    );
  }

  getAllEstimations(branch, vocType, year): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(apiUrl + `VBEstimation/RetailEstimation/BranchCode=${branch}/VocType=${vocType}/YearMonth=${year}/`);
        // return this.http.get(apiUrl + `VBEstimation/RetailEstimation/${branch}/${vocType}/${year}`);

      })
    );
  }
  getAllPosList(branch, vocType, year): Observable<any> {

    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + `VBEstimation/VBEstimationRetailSales/${branch}/${vocType}/${year}`);
      })
    );
  }

  /* Delete API */
  /*deleteProduct_SalesOrderEdit(sessionId = "", designCode = "", UniqueDesignID=""): Observable<any> {
    return this.http.delete(apiUrl+'SalesOrderEdit?SESSION_ID='+sessionId+'&DESIGN_CODE='+designCode+'&UniqueDesignID='+UniqueDesignID);
  }

  deleteProduct_SalesOrderEdit_bySessionID(sessionId = ""): Observable<any> {
    return this.http.delete(apiUrl+'SalesOrderEdit?SESSION_ID='+sessionId);
  }

  deleteProduct_SalesOrderEditComponents(sessionId = "", designCode = "", UNQ_DESIGN_ID=""): Observable<any> {
    return this.http.delete(apiUrl+'SalesOrderEditComponents?SESSION_ID='+sessionId+'&strDesignCode='+ designCode+'&UNQ_DESIGN_ID='+UNQ_DESIGN_ID);
  } */

  deleteProduct_SalesOrderEdit(
    sessionId = '',
    designCode = '',
    UniqueDesignID = ''
  ): Observable<any> {

    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(
          apiUrl +
          'SalesOrderEdit/Delete?SESSION_ID=' +
          sessionId +
          '&DESIGN_CODE=' +
          designCode +
          '&UniqueDesignID=' +
          UniqueDesignID
        );
      })
    );
  }

  deleteProduct_SalesOrderEdit_bySessionID(sessionId = ''): Observable<any> {

    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(
          apiUrl + 'SalesOrderEdit/Delete?SESSION_ID=' + sessionId
        );
      })
    );
  }

  deleteProduct_SalesOrderEditComponents(
    sessionId = '',
    designCode = '',
    UNQ_DESIGN_ID = ''
  ): Observable<any> {

    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(
          apiUrl +
          'SalesOrderEditComponents/Delete?SESSION_ID=' +
          sessionId +
          '&strDesignCode=' +
          designCode +
          '&UNQ_DESIGN_ID=' +
          UNQ_DESIGN_ID
        );
      })
    );
  }

  /* Put API */

  /*editProduct_SalesOrder(sessionId = "", designCode = "", UNQ_DESIGN_ID="",COMPSLNO=1, records): Observable<any> {
    return this.http.put(apiUrl+'SalesOrderEdit?SESSION_ID='+sessionId+'&DESIGN_CODE='+ designCode+'&UNQ_DESIGN_ID='+UNQ_DESIGN_ID+'&COMPSLNO='+COMPSLNO, records, this.httpOptions);
  } */

  editProduct_SalesOrder(
    sessionId = '',
    designCode = '',
    UNQ_DESIGN_ID = '',
    COMPSLNO = 1,
    records
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.put(
          apiUrl +
          'SalesOrderEdit/UpdateItemAttribute?SESSION_ID=' +
          sessionId +
          '&DESIGN_CODE=' +
          designCode +
          '&UNQ_DESIGN_ID=' +
          UNQ_DESIGN_ID,
          records,
          this.httpOptions
        );
      })
    );
  }

  editProduct_SalesOrderEditComponents(
    sessionId = '',
    designCode = '',
    UNQ_DESIGN_ID = '',
    COMPSLNO = 1,
    records
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;


        return this.http.put(
          apiUrl +
          'SalesOrderEditComponents?SESSION_ID=' +
          sessionId +
          '&DESIGN_CODE=' +
          designCode +
          '&UNQ_DESIGN_ID=' +
          UNQ_DESIGN_ID +
          '&COMPSLNO=' +
          COMPSLNO,
          records,
          this.httpOptions
        );
      })
    );
  }

  updateKaratColorinWishlist(SESSION_ID = '', KaratCode = '', Color = '') {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.put(
          apiUrl +
          'SalesOrderEdit?SESSION_ID=' +
          SESSION_ID +
          '&KaratCode=' +
          KaratCode +
          '&Color=' +
          Color,
          this.httpOptions
        );
      })
    );
  }

  /* Post API */
  postProductComponents(records): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.post(
          apiUrl + 'SalesOrderEditComponents',
          records,
          this.httpOptions
        );
      })
    );
  }

  postProduct_SalesOrderEdit(record): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.post(apiUrl + 'SalesOrderEdit', record, this.httpOptions);
      })
    );
  }

  postSalesOrder(records): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.post(apiUrl + 'SalesOrder', records, this.httpOptions);
      })
    );
  }

  /* Sales Order Services End */

  /* Company Parameter */
  getCompanyParameters(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(apiUrl + 'VBEstimation/VBEstimationCompanyParameter');
        // return this.http.get(apiUrl + 'CompanyParameters');
      })
    );
  }

  // use: dynamic function for get API data
  // getCompanyParameters(): Observable<any> {
  //   return this.configService.getConfig().pipe(
  //     switchMap((config:any) => {
  //       const apiUrl = config.baseUrl;
  //       const response = this.http.get(apiUrl + 'VBEstimation/VBEstimationCompanyParameter');
  //       return response
  //     })
  //   );
  // }

  /* Login */
  getUserName(username): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(apiUrl + 'VBEstimation/VBEstimationUserDetails/' + username + '');
        // return this.http.get(apiUrl + 'UserDetailNetMaster?USERNAME=' + username);
      })
    );
  }

  checkUserNamePassword(username, password): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;


        return this.http.get(
          apiUrl +
          'VBEstimation/VBEstimationPasswordValidation/Username=' +
          username +
          '/Password=' +
          password
        );
        // return this.http.get(
        //   apiUrl +
        //   'ValidatePassword?strusername=' +
        //   username +
        //   '&strPassword=' +
        //   password
        // );
      })
    );
  }

  updatePassword(username, password): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.put(
          apiUrl +
          'VBEstimation/VBEstimationPasswordValidation/Username=' +
          username +
          '/Password=' +
          password,
          ''
        );
        // return this.http.get(
        //   apiUrl +
        //   'ValidatePassword?strusername=' +
        //   username +
        //   '&strPassword=' +
        //   password
        // );
      })
    );
  }

  getUserBranch(username): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'VBEstimation/VBEstimationUserBranches/' + username + '');
        // return this.http.get(apiUrl + 'UseBranchNetMaster/' + username + '');
        // return this.http.get(apiUrl + 'UseBranchNetMaster?USERNAME=' + username);
      })
    );
  }

  getUserBranchDetails(branchcode): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(apiUrl + 'VBEstimation/VBEstimationBranchMaster/BRANCH_CODE=' + branchcode);
        // return this.http.get(apiUrl + 'BranchMaster?BranchCode=' + branchcode);
      })
    );
  }

  getUFinancialYear(branchcode, username): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(
          apiUrl + `FinancialYear?branchcode=${branchcode}&strusername=${username}`
        );
      })
    );
  }

  /* Menus */
  getLeftMenus(branchCode): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          `VBEstimation/VBPOSMenu/BranchCode=${branchCode}`
          // 'MenuListLeftPan?strRoleCode=Admin&strusername=Admin&branchcode=HO&strlanguage=ENGLISH&strCountry=SAU'
        );
      })
    );
  }

  getMenusPage(branchCode, userName, btnId, roleId): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'MenuPage?branchcode=' + branchCode + '&strusername=' + userName + '&strbuttonID=' + btnId + '&strRoleId=' + roleId
        );
      })
    );
  }


  getTransactionMain(strBranchCode, strMainVoctype, strVocType, strYearMonth): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'TransactionMain?strBranchCode=' + strBranchCode + '&strMainVoctype=' + strMainVoctype + '&strVocType=' + strVocType + '&strYearMonth=' + strYearMonth
        );
      })
    );
  }


  /* POS started on 20-09-2021 */
  getPOS_Stockdetails_withoutcost(stock_code): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl + 'TableViewWithoutCost?STOCK_CODE=' + stock_code
        );
      })
    );
  }

  getPOS_RetailSalesItemImage(stock_code): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl + 'RetailSalesItemImage/' + stock_code
          // apiUrl + 'RetailSalesItemImage?StrstockCode=' + stock_code
        );
      })
    );
  }

  getPOS_Stockdetails_branchwise_Metalcost(
    stock_code,
    branch_code
  ): Observable<any> {
    // https://angulardata.sunwebapps.com/api/BranchMetalCost?BMC_SUB_STOCK_CODE=GNK00020&BMC_BRANCH_CODE=DM
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'BranchMetalCost?BMC_SUB_STOCK_CODE=' +
          stock_code +
          '&BMC_BRANCH_CODE=' +
          branch_code
        );
      })
    );
  }

  getPOS_Stockdetails_branchwise_withcost(
    stock_code,
    branch_code
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(
          apiUrl +
          'BranchDiamondCost?BDC_STOCK_CODE=' +
          stock_code +
          '&BDC_BRANCH_CODE=' +
          branch_code
        );
      })
    );
  }

  getDivisionDetails(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'DivisionMaster');
      })
    );
  }

  getDivisionMaster(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'VBEstimation/VBDivisionMaster');
      })
    );
  }

  getSettingGrid(voc_type, branch_code): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'TrnFormGridSettingsBlank?VocType=' +
          voc_type +
          '&BranchCode=' +
          branch_code
        );
      })
    );
  }

  getUserList(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'TrnFormGridSettingsBlank/GetuserLsit');
      })
    );
  }

  getPosCustomerList(mobile_number): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'VBEstimation/VBPOSCustomerMaster/Mobile=' + mobile_number);
        // return this.http.get(apiUrl + 'PosCustomerMaster?MOBILE=' + mobile_number);
      })
    );
  }

  insertPosCustomer(data): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.post(apiUrl + 'VBEstimation/VBPOSCustomerMaster/', data);
      })
    );
  }
  updatePosCustomer(data): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.put(apiUrl + `VBEstimation/VBPOSCustomerMaster/${data.CODE}`, data);
      })
    );
  }

  getAMLValidation(data): Observable<any> {
    // http://185.56.89.191:91/api/VBEstimation/VBEstimationAMLValidation/MeenaJewellers/Meena/MeenaJewellers@980/M379655/John//Morish/98/M379655//2000-08-11T20:00:00/PPNO/meena786/98/MeenaJewellers/1/http://192.168.1.10:99
    // return this.http.get(apiUrl +'VBEstimation/VBEstimationAMLValidation/' + encodeURIComponent(`MeenaJewellers/Meena/MeenaJewellers@980/M379655/John//Morish/98/M379655//2000-08-11T20:00:00/PPNO/meena786/98/MeenaJewellers/1/http://192.168.1.10:99`));
    // return this.http.get(apiUrl + `VBEstimation/VBEstimationAMLValidation/${data.AMLDIGICOMPANYNAME}/${data.AMLDIGIUSERNAME}/${data.AMLDIGIPASSWORD}/${data.CODE}/${data.FIRSTNAME}/${data.MIDDLENAME}/${data.LASTNAME}/${data.AMLDIGITHRESHOLD}/${data.CODE}/${data.NATIONALITY}/${data.DATE_OF_BIRTH}/${data.CUST_Type}/${data.AMLUSERID}/${data.AMLDIGITHRESHOLD}/${data.AMLDIGICOMPANYNAME}/1/${data.DIGIIPPATH}`);
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + `VBEstimation/VBEstimationAMLValidation?companyname=${data.AMLDIGICOMPANYNAME}&username=${data.AMLDIGIUSERNAME}&Password=${data.AMLDIGIPASSWORD}&CustomerId=${data.CODE}&FirstName=${data.FIRSTNAME}&MiddleName=${data.MIDDLENAME}&LastName=${data.LASTNAME}&MatchCategory=&CustomerIdNumber=${data.CustomerIdNumber}&Nationality=${data.NATIONALITY}&DOB=${data.DATE_OF_BIRTH}&CustomerType=${data.CUST_Type}&UserId=${data.AMLUSERID}&Threshold=${data.AMLDIGITHRESHOLD}&CompName=${data.AMLDIGICOMPANYNAME}&GeneratePayload=1&IPPath=${data.DIGIIPPATH}&Gender=${data.Gender}&CustomerIdType=${data.CustomerIdType}`);

      })
    );
  }
  // http://185.56.89.191:91/api/VBEstimation/VBPOSCustomerMaster/code=212/AmlNameValidation=true

  updateAMLNameValidation(code, value): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.put(apiUrl + `VBEstimation/VBPOSCustomerMaster/code=${code}/AmlNameValidation=${value}`, '');
      })
    );
  }

  updateAMLDigiScreenValidation(code, value): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.put(apiUrl + `VBEstimation/VBPOSCustomerMaster/code=${code}/DigiScreened=${value}`, '');
      })
    );
  }

  getGeneralMaster(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + `VBEstimation/VBGeneralMaster/`);
        // return this.http.get(apiUrl + 'GeneralMaster/ID%20MASTER');
        // return this.http.get(apiUrl + 'GeneralMaster?Types=ID%20MASTER');
      })
    );
  }
  getGeneralMasterByType(type): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + `VBEstimation/VBGeneralMaster/${type}`);
        // return this.http.get(apiUrl + 'GeneralMaster/ID%20MASTER');
        // return this.http.get(apiUrl + 'GeneralMaster?Types=ID%20MASTER');

      })
    );
  }


  getGeneralMasterByTypeAndId(type, countryCode = null, stateCode = null): Observable<any> {
    return this.configService.getConfig().pipe(
          switchMap((config: any) => {
            const apiUrl = config.baseUrl;

            var url;
            if (countryCode == null && stateCode == null) {
              url = this.http.get(apiUrl + `VBEstimation/VBGeneralMaster/${encodeURIComponent(type)}`)
            } else if (stateCode == null) {
              url = this.http.get(apiUrl + `VBEstimation/VBGeneralMaster/GetGeneralMasterListWithTypesandCountryCode/${encodeURIComponent(type)}/${encodeURIComponent(countryCode)}`)
            } else {
              url = this.http.get(apiUrl + `VBEstimation/VBGeneralMaster/GetGeneralMasterListWithTypesCountryStateCode/${encodeURIComponent(type)}/${encodeURIComponent(countryCode)}/${encodeURIComponent(stateCode)}`)
            }
            return url;

            // return this.http.get(apiUrl + `VBEstimation/VBGeneralMaster/${type}`);

          })
        );


  }

  getComboFilter(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + `VBEstimation/VBComboFilter/`);
      })
    );
  }

  getIdMasterList(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'VBEstimation/VBGeneralMaster/ID%20MASTER');
        // return this.http.get(apiUrl + 'GeneralMaster/ID%20MASTER');
        // return this.http.get(apiUrl + 'GeneralMaster?Types=ID%20MASTER');
      })
    );
  }

  getCreditCardMaster(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'VBEstimation/VBEstimationCreditCardMaster');
        // return this.http.get(apiUrl + 'CreditCardMaster');
      })
    );
  }

  getBranchKaratRate(branch_code): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(
          apiUrl + 'VBEstimation/VBBranchKaratRate/BranchCode=' + branch_code
          // apiUrl + 'BranchKaratRate?BRANCH_CODE=' + branch_code
        );
      })
    );
  }

  // getPOSStockCodeValidation(
  //   strstockCode,
  //   strBranchCode,
  //   strusername
  // ): Observable<any> {
  //   return this.http.get(
  //     apiUrl +
  //     'POSStockCodeValidation?strstockCode=' +
  //     strstockCode +
  //     '&strBranchCode=' +
  //     strBranchCode +
  //     '&strusername=' +
  //     strusername
  //   );
  // }
  getPOSStockCodeValidation(
    strstockCode,
    strBranchCode,
    strusername,
    vocType,
    vocDate,
    locCode = null
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +

          `VBEstimation/VBEstimationStockCodeValidation/StockCode=${strstockCode}/BranchCode=${strBranchCode}/VocType=${vocType}/UserName=${strusername}/Location=${locCode}/PartyCode=%27%27/VocDate=${vocDate}`
        );
        // return this.http.get(
        //   apiUrl +

        //    'VBEstimation/VBEstimationStockCodeValidation?stockcode=' +
        //   //  'EstimationVB/GetStockCodeValidation?stockcode=' +
        //   // 'StockCodeValidation?stkcode=' +
        //   strstockCode +
        //   '&branchcode=' +
        //   strBranchCode +
        //   '&voctype=' +
        //   vocType +
        //   '&username=' +
        //   strusername +
        //   '&location=%27%27 &partycode=%27%27 &vocdate=' +
        //   vocDate
        // );
        // return this.http.get(
        //   apiUrl +
        //   'EstimationVB/GetStockCodeValidation?stkcode=' +
        //   // 'StockCodeValidation?stkcode=' +
        //   strstockCode +
        //   '&brcode=' +
        //   strBranchCode +
        //   '&voctyp=' +
        //   vocType +
        //   '&usrnm=' +
        //   strusername +
        //   '&loc=%27%27 &prtycode=%27%27 &vocdt=' +
        //   vocDate
        // );
      })
    );
  }
  getPOSStockCodeValidation_temp(
    strstockCode,
    strBranchCode,
    strusername
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'BranchKaratRate?BRANCH_CODE=DMCC');
      })
    );
  }

  getRetailSalesItemVatInfo(branch_code): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(
          apiUrl +
          'RetailSalesItemVatInfo?strBranchCode=' +
          branch_code +
          '&strstockCode=22KG&strVocType=POS&strdate=2021-03-22&strpartyCode=%20%20%20%27%27'
        );
      })
    );
  }

  getRetailSReturnLookUp(
    branch_code,
    voctype,
    vocno,
    yearmonth
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;

        return this.http.get(

          apiUrl +
          'VBEstimation/VBRetailSReturnLookup/BranchCode=' +
          branch_code +
          '/VocType=' +
          voctype +
          '/VocNo=' +
          vocno +
          '/YearMonth=' +
          yearmonth
          // apiUrl +
          // 'RetailSReturnLookUp?strBranchCode=' +
          // branch_code +
          // '&strVoctype=' +
          // voctype +
          // '&intVocNo=' +
          // vocno +
          // '&stryearmonth=' +
          // yearmonth
        );
      })
    );
  }

  getRetailsalesExchangeLookup(branchCode): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + `VBEstimation/VBRetailSalesExchangeLookup?strBranchCode=${branchCode}`);
      })
    );
  }

  getLanguageDictionary(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'LanguageDictionary/');
      })
    );
  }

  getLanguageDictionaryWeb(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'LanguageDictionaryWeb/');
      })
    );
  }

  getTransactionDetailFormGrid(
    branch_code,
    voctype,
    vocno,
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'TrnsDetailFormGrid?strBranchCode=' +
          branch_code +
          '&strMainVoctype=' +
          voctype +
          '&strVocType=' +
          vocno
        );
      })
    );
  }
  // vignesh api

  getLookup(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'LookUp?strLookupId=4&strWhereCondition=WHERE%20ITEM%20=%20%27D%27');
      })
    );
  }

  // end vignesh

  // CurrencyReceipt by PG.Moorthy
  getMainGridDetailFormGridData(
    branch_code,
    voctype,
    vocno,
    year
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'CurrencyReceipt?BRANCH_CODE=' +
          branch_code +
          '&VOCTYPE=' +
          voctype +
          '&VOCNO=' +
          vocno
          + '&YEARMONTH=' +
          year
        );
      })
    );
  }

  generalMasterCodeValidate(type) {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + `GeneralMaster?Types=${type}`);
      })
    );
  }
  // sendsalesorderdetails(e :any)
  // {
  //   console.log(e);
  //   this.dataSource.next(e);
  // }
  getPOSStockCodeValidationCode(
    strstockCode,
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'TableViewWithoutCost?STOCK_CODE=' +
          strstockCode
        );
      })
    );
  }


  postRetailSalesMaster(
    postData
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.post(
          apiUrl +
          'VBEstimation/VBEstimationRetailSalesData',
          // 'VBEstimationRetailSalesData',
          // 'EstimationVB/GetStockCodeValidation',
          postData
        );
      })
    );
  }
  postRetailEstimationMaster(
    postData
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.post(
          apiUrl +
          'VBEstimation/RetailEstimation',
          // 'VBEstimationRetailSalesData',
          // 'EstimationVB/GetStockCodeValidation',
          postData
        );
      })
    );
  }
  updateRetailEstimationMaster(
    data,
    postData
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.put(
          apiUrl +
          `VBEstimation/RetailEstimation?strBranchCode=${data.branchCode}&strVocType=${data.vocType}&strYearMonth=${data.yearMonth}&intVocNo=${data.vocNo}`,
          postData
        );
      })
    );
  }
  updateRetailSalesMaster(
    data,
    postData
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.put(
          apiUrl +
          `VBEstimation/VBEstimationRetailSalesData?strBranchCode=${data.branchCode}&strVocType=${data.vocType}&strYearMonth=${data.yearMonth}&intVocNo=${data.vocNo}`,
          postData
        );
      })
    );
  }

  getRetailSalesMaster(
    data
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          `VBEstimation/VBEstimationRetailSalesData?strBranchCode=${data.branchCode}&strVocType=${data.vocType}&strYearMonth=${data.yearMonth}&intVocNo=${data.vocNo}`
        );
      })
    );
  }
  getRetailEstimationMaster(
    data
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          `VBEstimation/RetailEstimation?strBranchCode=${data.branchCode}&strVocType=${data.vocType}&strYearMonth=${data.yearMonth}&intVocNo=${data.vocNo}`
        );
      })
    );
  }

  getBranchCurrencyMaster(
    // userBranch
  ): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(
          apiUrl +
          'VBEstimation/VBEstimationCurrencyMaster'
          // 'VBEstimation/VBEstimationCurrencyMaster/CurrencyCode=' + userBranch + ''
          // 'BranchCurrencyMaster?CMBRANCH_CODE=' + userBranch + ''
        );
      })
    );
  }

  // Get all messagebox
  getAllMessageBox(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'Messagebox');
      })
    );
  }

  getReceiptModes(): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'VBEstimation/VBPaymentButtons');
      })
    );
  }

  insertDocTransLog(ipAddress, postData): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.post(apiUrl + `VBDocTransLog/InsertDocTransLog?ipAddress=${ipAddress}`, postData);
      })
    );
  }

  getCityByCountry(countryCode): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + `VBEstimation/VBGeneralMaster/GetGeneralMasterListWithTypesandCountryCode/CITY%20MASTER/${countryCode}`);
      })
    );
  }

  getIpAddress(): Observable<any> {
    return this.http.get('http://api.ipify.org/?format=json');
  }

  getCustomerDetailByCode(code): Observable<any> {
    return this.configService.getConfig().pipe(
      switchMap((config: any) => {
        const apiUrl = config.baseUrl;
        return this.http.get(apiUrl + 'VBEstimation/VBPOSCustomerMaster/Code=' + code);
      })
    );
  }
}
