import { CommonfuncService } from 'src/app/commonfunc.service';
import { SuntechapiService } from 'src/app/suntechapi.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ApiConfigService } from './apiconfig.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  accessToken: any;
  userBranch: any;

  constructor(
    private router: Router,
    private suntechApi: SuntechapiService,
    private comFunc: CommonfuncService,
    private datePipe: DatePipe,

  ) {
    // this.pageRedirect();
    // this.suntechApi.getConfigUrl();
  }


  getDivisionMaster() {
    this.suntechApi.getDivisionMaster().subscribe(data => {
      if (data.status == "Success") {
        this.comFunc.divisionMasterList = data.Details;
      }
    });
  }
  async getGeneralMaster() {
    this.suntechApi.getGeneralMaster().subscribe(async data => {
      // console.table(data)
      if (data.status == "Success") {
        this.comFunc.mastersList = await data.response;
        sessionStorage.setItem('generalMastersList', JSON.stringify(data.response));
      } else {
        this.comFunc.mastersList = [];
        sessionStorage.setItem('generalMastersList', JSON.stringify([]));

      }
    });
  }
  async getComboFilter() {
    this.suntechApi.getComboFilter().subscribe(async data => {
      // console.table(data)
      if (data.status == "Success") {
        this.comFunc.comboFilter = await data.response;
        sessionStorage.setItem('comboFilterList', JSON.stringify(data.response));
      } else {
        this.comFunc.comboFilter = [];
        sessionStorage.setItem('comboFilterList', JSON.stringify(data.response));
      }
    });
  }
  getBranchCurrencyMaster() {
    this.suntechApi
      .getBranchCurrencyMaster()
      // this.suntechApi.getBranchCurrencyMaster(branch)
      .subscribe((data) => {
        // this.comFunc.allBranchCurrency = data.response;
        console.log('===============curr=====================');
        console.log(data);
        console.log('====================================');
        this.comFunc.allBranchCurrency = data.currencyMaster;
      });
  }

  async getAllCompanyParameters() {
    let map = new Map();
    console.log("getDivisionMaster called");


    this.suntechApi.getCompanyParameters().subscribe(async (resp) => {
      if (resp.status == 'Success') {
        this.suntechApi.setCompanyParamSubject('testsubject');
        // console.log(resp.response.length);
        // for (var i = 0; i < resp.response.length; i++) {
        //   map.set(resp.response[i].PARAMETER, resp.response[i].PARAM_VALUE);
        // }
        // let jsonObject = {};
        // map.forEach((value, key) => {
        //   jsonObject[key] = value;
        // });
        // this.comFunc.allCompanyParams = jsonObject;
        this.comFunc.allCompanyParams = await resp.response;
        // console.log('====================================');
        // console.log(jsonObject);
        // console.log('====================================');
        this.comFunc.allCompanyParams.filter((data) => {
          if (data.Parameter == 'AMTFORMAT')
            this.comFunc.amtFormat = data.Param_Value;
          if (data.Parameter == 'MQTYFORMAT')
            this.comFunc.mQtyFormat = data.Param_Value;
          if (data.Parameter == 'AMTDECIMALS')
            this.comFunc.amtDecimals = data.Param_Value;
          if (data.Parameter == 'MQTYDECIMALS')
            this.comFunc.mQtyDecimals = data.Param_Value;
          if (data.Parameter == 'POSSHOPCTRLAC')
            this.comFunc.basePartyCode = data.Param_Value;
          if (data.Parameter == 'POSKARATRATECHANGE')
            this.comFunc.posKARATRATECHANGE = data.Param_Value;

          if (data.Parameter == 'COMPANYCURRENCY') {
            // alert('COMPANYCURRENCY '+ data.Param_Value)
            this.comFunc.compCurrency = data.Param_Value;
          }
        });
        // console.log('===============amtFormat=====================');
        // console.log(this.comFunc.amtFormat);
        // console.log(this.comFunc.compCurrency);
        // console.log('====================================');
      }
    });
  }
  getAllMessageBox() {
    this.suntechApi.getAllMessageBox().subscribe((resp) => {
      // console.log(resp);

      if (resp.status == 'Success') {
        // if (resp.Status == 'Success') {
        this.comFunc.allMessageBoxData = resp.response;
        console.log(this.comFunc.allMessageBoxData);
      }
    });
  }
  pageRedirect() {
    this.accessToken = localStorage.getItem('username');

    // console.log(this.accessToken);

    if (
      this.accessToken != null &&
      this.accessToken != '' &&
      this.accessToken != undefined
    )
      // this.router.navigateByUrl('salesorders')
      this.router.navigate(['salesorders']);
    // this.router.navigateByUrl('')
    else this.router.navigate(['']);
  }
  ngOnInit() {

    this.checkAppVersionAndLogout();

    this.userBranch = localStorage.getItem('userbranch');
    this.comFunc.allbranchMaster = JSON.parse(localStorage.getItem('branchdetails'));
    this.comFunc.mastersList = JSON.parse(sessionStorage.getItem('generalMastersList'));
    this.comFunc.comboFilter = JSON.parse(sessionStorage.getItem('comboFilterList'));

    // this.suntechApi.getConfigUrl().then((data) => {
    this.getAllCompanyParameters();
    this.getBranchCurrencyMaster();
    this.getAllMessageBox();
    this.getDivisionMaster();
    this.getGeneralMaster();
    this.getComboFilter();
    // })

    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   window.scrollTo(0, 0);
    // });
  }

  checkAppVersionAndLogout() {
    let currentAppVersion = localStorage.getItem('appVersion');
    let updatedAppVersion = environment.appVersion;
    let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
    if (currentAppVersion != updatedAppVersion && isLoggedIn) {
      this.logout();
    }
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  }

  cDateFormat(value) {
    return this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss');
  }
}
