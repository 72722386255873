<div class="modal-header">
  <h5 class="modal-title" id="modal-dia-metal">Stock Detail</h5>
  <button type="button" class="btn btn-close" (click)="close()"><i
      class="feather icon-x"></i></button>
</div>
<div class="modal-body stockdetail">
  <form [formGroup]='lineItemForm'>
  <div class="container">


    <div class="row">
      <div class="col-1">
      <mat-form-field>
        <mat-label>Division</mat-label>
        <input matInput type="text" placeholder=""  formControlName="txtDivision" id="txtDivisionEle"  #txtDivisionEle >
      </mat-form-field>
    </div>
      <div class="col-3">
        <mat-form-field>
          <mat-label>Stock Code</mat-label>
          <!-- <mat-icon matSuffix class="srchicon">search</mat-icon> -->
          <input matInput  #txtItemCodeEle type="text" id="txtItemCode"    formControlName="txtItemCode"
          (focusout)="txtItemCode_Validating($event)"
          (keydown)="txtItemCode_KeyDown($event)" >
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field>
          <mat-label>Item Description</mat-label>
          <input matInput type="text" placeholder=""  id="txtItemDescription"    formControlName="txtItemDescription">
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field>
          <mat-label>Location</mat-label>
          <input matInput type="text" placeholder=""  id="txtLocation" formControlName="txtLocation">
        </mat-form-field>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-6 mt-3">
            <mat-checkbox>
              <mat-label>Inclusive </mat-label>
            </mat-checkbox>
          </div>
          <div class="col-6">
            <mat-form-field>
              <mat-label>Supplier</mat-label>
              <input matInput  placeholder="" type="text" id="txtSupplier" formControlName="txtSupplier" >
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <mat-form-field>
          <mat-label >Design</mat-label>
          <input matInput type="text"  formControlName="txtDesign" id="txtDesign">
        </mat-form-field>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>Color</mat-label>
              <input matInput type="text" id="txtColor" formControlName="txtColor">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field>
              <mat-label>Size</mat-label>
              <input matInput type="text" id="txtSize" formControlName="txtSize" >
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field>
          <mat-label>Salesman</mat-label>
          <mat-icon matSuffix class="srchicon">search</mat-icon>
          <input matInput type="text" placeholder=""  id="txtSalesPersonCode" formControlName="txtSalesPersonCode">
        </mat-form-field>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-6 mt-3">
            <mat-checkbox>
              <mat-label>Loyalty Stock</mat-label>
            </mat-checkbox>
          </div>
          <div class="col-6">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput placeholder=""  id="txtMainStockCode" formControlName="txtMainStockCode">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

  </div>

  <mat-tab-group headerPosition="below" [selectedIndex]="this.divisionMS == 'M' ? 0 : 1 ">
    <mat-tab label="Metal">
      <div class="row mt-2">
        <div class="col-9">
          <!-- quantity start -->
          <u><h6>Quantity - Places & Weight</h6></u>
          <div class="row">
            <div class="col-2">
              <mat-form-field>
                <mat-label>Units</mat-label>
                <input type="text" matInput placeholder=""  id="cmbMcUnit" formControlName="cmbMcUnit" >
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field>
                <mat-label>Pieces</mat-label>
                <input type="number" matInput placeholder=""  id="txtMPcs" formControlName="txtMPcs">
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field>
                <mat-label>Gross Weight</mat-label>
                <input type="number" matInput #txtMGrssWtEle id="txtMGrssWt" formControlName="txtMGrssWt" >
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field>
                <mat-label>Stone Weight</mat-label>
                <input matInput  type="number" placeholder=""  id="txtMStoneWt" formControlName="txtMStoneWt">
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field>
                <mat-label>Net Weight</mat-label>
                <input matInput type="number" placeholder=""  id="txtMNetWt" formControlName="txtMNetWt">
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field>
                <mat-label>Chargable Weight</mat-label>
                <input matInput type="number" placeholder=""  id="txtMChargable" formControlName="txtMChargable">
              </mat-form-field>
            </div>
          </div>
          <!-- quantity end -->
          <!-- <table mat-table class="mat-elevation-z8">
            <mat-text-column name="Rates & Values"></mat-text-column>
            <mat-text-column name="Weight"></mat-text-column>
            <mat-text-column name="Rate"></mat-text-column>
            <mat-text-column name="Amount"></mat-text-column>
            <mat-text-column name="Discount"></mat-text-column>
            <mat-text-column name="NetAmount"></mat-text-column>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table> -->
          <div class="row">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th class="colbg" colspan="2" scope="col">Rates & Values</th>
                    <th class="colbg" scope="col">Weight</th>
                    <th class="colbg" scope="col">Rate</th>
                    <th class="colbg" scope="col">Amount</th>
                    <th class="colbg" scope="col">Discount</th>
                    <th class="colbg" scope="col">Net Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th class="noneborder" colspan="2" scope="row">Making Charge</th>
                    <td><input type="text" class="inputfield" placeholder="" id="txtMakingValueWt" formControlName="txtMakingValueWt"></td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtMMkgRate" formControlName="txtMMkgRate"></td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtMMkgAmt" formControlName="txtMMkgAmt"></td>

                    <td>
                     <div>
                      <input type="text" class="inputfield quarter-width" placeholder="" id="txtMdiscPerc" formControlName="txtMdiscPerc">
                      %
                      <input type="text" class="inputfield half-width" placeholder="" id="txtMDiscAmt" formControlName="txtMDiscAmt">
                     </div>
                    </td>
                    <td><input type="text" class="inputfield" placeholder="" id="txtMMakingNetAmount" formControlName="txtMMakingNetAmount"></td>

                  </tr>
                  <tr>
                    <th class="noneborder" colspan="2" scope="row">Metal Value</th>
                    <td><input type="text" class="inputfield" placeholder="" id="txtMetalValueWt" formControlName="txtMetalValueWt"></td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtMMetalRate" formControlName="txtMMetalRate"></td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtMMetalAmt" formControlName="txtMMetalAmt"></td>

                    <td>
                      <!-- <input type="text" class="inputfield" placeholder="" id="txtMMakingNetAmount" formControlName="txtMMakingNetAmount"> -->
                    </td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtMMetalNetAmount" formControlName="txtMMetalNetAmount"></td>

                  </tr>
                  <tr>
                    <th class="noneborder" colspan="2" scope="row">Stone Charges</th>
                    <td><input type="text" class="inputfield" placeholder="" id="txtStoneValueWt" formControlName="txtStoneValueWt"></td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtMStoneRate" formControlName="txtMStoneRate"></td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtMStoneAmt" formControlName="txtMStoneAmt"></td>

                    <td>
                      <!-- <input type="text" class="inputfield"> -->
                    </td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtMStoneNetAmount" formControlName="txtMStoneNetAmount"></td>

                  </tr>
                  <tr>
                    <th class="noneborder" colspan="2" scope="row">Stamp Charge</th>
                    <td><input type="text" class="inputfield" placeholder="" id="txtStampValueWt" formControlName="txtStampValueWt"></td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtStampRate" formControlName="txtStampRate"></td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtStampAmount" formControlName="txtStampAmount"></td>

                    <td>
                      <!-- <input type="text" class="inputfield"> -->
                    </td>

                    <td><input type="text" class="inputfield" placeholder="" id="txtMStampNetAmount" formControlName="txtMStampNetAmount"></td>

                  </tr>
                  <tr>
                    <th class="noneborder" colspan="2" scope="row">
                      <div class="wastage-custom">
                        <p class="heading">Wastage</p>
                          <input type="text" class="inputfield2 half-width">
                         <p class="end">
                          %
                         </p>
                      </div>
                    </th>
                    <!-- <td><input type="text" class="inputfield"></td>
 -->
                    <td><input type="text" class="inputfield"></td>

                    <td><input type="text" class="inputfield"></td>

                    <td><input type="text" class="inputfield"></td>

                    <td><input type="text" class="inputfield"></td>

                    <td><input type="text" class="inputfield"></td>

                  </tr>
                  <tr>
                    <th class="noneborder" colspan="2" scope="row"></th>
                    <th class="noneborder">Item Cost</th>
                    <td><input type="text" class="inputfield"></td>

                    <th colspan="2" class="colbg">Total Amount</th>
                    <td><input type="text" class="inputfield"></td>

                  </tr>
                  <tr>
                    <th class="colbg">Availabe Stock</th>
                    <th class="colbg">Stone Difference</th>
                    <th class="noneborder" colspan="2" scope="row"></th>
                    <th colspan="2" class="colbg">VAT %</th>
                    <td><input type="text" class="inputfield"></td>

                  </tr>
                  <tr>
                    <td><input type="text" class="inputfield"></td>

                    <td><input type="text" class="inputfield"></td>

                    <th class="noneborder" colspan="2" scope="row"></th>
                    <th colspan="2" class="colbg">NET AMOUNT</th>
                    <td><input type="text" class="inputfield" id="txtMTotAmt" formControlName="txtMTotAmt"></td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="box">
            <div  class="productImage text-center">

              <img  *ngIf="curr_line_item_images" class="card-img-top imageContainer" src="https://angulardata.sunwebapps.com/{{curr_line_item_images}}"
                alt="Card image cap" loading="lazy"  >
            </div>
            <!-- <div style="height:150px;text-align:center;" class="productImage">
              <img class="card-img-top" src="https://angulardata.sunwebapps.com/{{curr_line_item_images}}"
                alt="Card image cap" loading="lazy" style="border:1px solid #ccc;height:150px;width:150px;">
            </div> -->
          </div>

        </div>
      </div>
      <div class="footer-btn">
        <button class="btn btn-primary">Continue</button>&nbsp;
        <button class="btn btn-primary">Save</button>&nbsp;
        <button class="btn btn-danger" (click)="close()">Cancel</button>&nbsp;
      </div>
    </mat-tab>
    <mat-tab label="Diamond">
      <div class="row mt-2">
        <div class="col-3">
          <div class="form-group">
            <label class="font-weight-bold col-form-label">Tag Details</label>
            <textarea class="form-control" rows="9"></textarea>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th class="colbg" scope="col">Pieces</th>
                    <th class="colbg" scope="col">Weight</th>
                    <th class="colbg" scope="col">Unit Rate</th>
                    <th class="colbg" scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><input type="text" class="inputfield"></td>

                    <td><input type="text" class="inputfield"></td>

                    <td><input type="text" class="inputfield"></td>

                    <td><input type="text" class="inputfield"></td>

                  </tr>
                  <tr>
                    <th colspan="2" class="colbg">Discount</th>
                    <td><input type="text" class="inputfield"></td>

                    <td><input type="text" class="inputfield"></td>

                  </tr>
                  <tr>
                    <th colspan="3" class="colbg">Item Total</th>
                    <td><input type="text" class="inputfield"></td>

                  </tr>
                  <tr>
                    <th colspan="2" class="colbg">VAT</th>
                    <td><input type="text" class="inputfield"></td>

                    <td><input type="text" class="inputfield"></td>

                  </tr>
                  <tr>
                    <th colspan="2" class="colbg">Item Cost</th>
                    <td><input type="text" class="inputfield"></td>
                  </tr>
                  <tr>
                    <th colspan="3" class="colbg">NET AMOUNT</th>
                    <td><input type="text" class="inputfield"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="box-diamond"></div>

        </div>
      </div>
      <div class="footer-btn">
        <button class="btn btn-primary">Continue</button>&nbsp;
        <button class="btn btn-primary">Save</button>&nbsp;
        <button class="btn btn-danger" (click)="close()">Cancel</button>&nbsp;
      </div>
    </mat-tab>
  </mat-tab-group>
  </form>
</div>
