import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsigmentStockComponent } from './consigment-stock/consigment-stock.component';
import { ConsignmentItemDetailComponent } from './consignment-item-detail/consignment-item-detail.component';
import { EstimationListComponent } from './estimation-list/estimation-list.component';
import { DiscountAuthorizationComponent } from './discount-authorization/discount-authorization.component';
import { DxDataGridModule } from 'devextreme-angular';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import { DxCheckBoxModule } from 'devextreme-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatCheckboxModule} from '@angular/material/checkbox';




@NgModule({
  declarations: [
    ConsigmentStockComponent,
    EstimationListComponent,
    DiscountAuthorizationComponent,
    ConsignmentItemDetailComponent

  ],
  imports: [
    CommonModule,
    DxDataGridModule,
    MatTabsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    DxCheckBoxModule,
    NgbModule,
    MatCheckboxModule
  ],
  exports: [
    ConsigmentStockComponent,
    EstimationListComponent,
    DiscountAuthorizationComponent,
    ConsignmentItemDetailComponent
  ]
})
export class ConsigmentModule { }
