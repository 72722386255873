import { Injectable } from '@angular/core';
import { SuntechapiService } from '../../../../suntechapi.service';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

/*const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'sales-order',
        title: 'Sales Order',
        type: 'item',
        url: '/salesorders',
        classes: 'nav-item',
        icon: 'feather icon-sidebar',
        breadcrumbs: false
      },
     {
        id: 'pos',
        title: 'POS',
        type: 'item',
        url: '/point-of-sales',
        classes: 'nav-item',
        icon: 'feather icon-book',
        breadcrumbs: false
      },
      {
        id: 'touchpos',
        title: 'Touch POS',
        type: 'item',
        url: '/pos-sales',
        classes: 'nav-item',
        icon: 'feather icon-monitor',
        breadcrumbs: false
      },
      {
        id: 'menu-level',
        title: 'Menu Levels',
        type: 'collapse',
        icon: 'feather icon-menu',
        children: [
          {
            id: 'menu-level-2.1',
            title: 'Menu Level 2.1',
            type: 'item',
            url: 'javascript:',
            external: true
          },
          {
            id: 'menu-level-2.2',
            title: 'Menu Level 2.2',
            type: 'collapse',
            children: [
              {
                id: 'menu-level-2.2.1',
                title: 'Menu Level 2.2.1',
                type: 'item',
                url: 'javascript:',
                external: true
              },
              {
                id: 'menu-level-2.2.2',
                title: 'Menu Level 2.2.2',
                type: 'item',
                url: 'javascript:',
                external: true
              }
            ]
          }
        ]
      }
    ]
  }
]; */

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      // {
      //   id: 'sales-order',
      //   title: 'Sales Order',
      //   type: 'item',
      //   url: '/salesorders',
      //   classes: 'nav-item',
      //   icon: 'feather icon-sidebar',
      //   breadcrumbs: false,
      // },
      // {
      //   id: 'pos-list',
      //   title: 'POS',
      //   type: 'item',
      //   url: '/pos',
      //   classes: 'nav-item',
      //   icon: 'feather icon-sidebar',
      //   breadcrumbs: false,
      // },
      {
        id: 'estimation-list',
        title: 'Estimation',
        type: 'item',
        url: '/estimation',
        classes: 'nav-item',
        icon: 'feather icon-sidebar',
        breadcrumbs: false,
      },

      // {
      //   id: 'quotation-list',
      //   title: 'Quotation',
      //   type: 'item',
      //   url: '/quotation-list',
      //   classes: 'nav-item',
      //   icon: 'feather icon-sidebar',
      //   breadcrumbs: false,
      // },

      // {
      //   id: 'touchpos',
      //   title: 'Estimation',
      //   type: 'item',
      //   url: '/add-estimation',
      //   classes: 'nav-item',
      //   icon: 'feather icon-monitor',
      //   breadcrumbs: false,
      // },
      // {
      //   id: 'Menus',
      //   title: 'Menu',
      //   type: 'item',
      //   url: '/right-menus',
      //   classes: 'nav-item',
      //   icon: 'feather icon-monitor',
      //   breadcrumbs: false,
      // },
    ],
  },
];

/*
Removed on 29 Mar 2022
{
  id: 'settings',
  title: 'Settings',
  type: 'item',
  url: '/settings',
  classes: 'nav-item',
  icon: 'feather icon-sidebar',
  breadcrumbs: false,
},
{
  id: 'pos',
  title: 'POS Demo',
  type: 'item',
  url: '/demo-pos',
  classes: 'nav-item',
  icon: 'feather icon-book',
  breadcrumbs: false,
},
{
  id: 'pos',
  title: 'POS',
  type: 'item',
  url: '/point-of-sales',
  classes: 'nav-item',
  icon: 'feather icon-book',
  breadcrumbs: false,
},
*/

/* const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'sales-order',
        title: 'Sales Order',
        type: 'item',
        url: '/salesorders',
        classes: 'nav-item',
        icon: 'feather icon-sidebar',
        breadcrumbs: false,
      }
    ],
  },
]; */

@Injectable()
export class NavigationItem {
  constructor(private suntechApi: SuntechapiService) {}

  public get() {
    /* let childMenu = NavigationItems[0].children;
    let totalChild = childMenu.length;
    let samp = {
      id: 'touchpos 1',
      title: 'Touch POS 1',
      type: 'item',
      url: '/pos-sales',
      classes: 'nav-item',
      icon: 'feather icon-monitor',
      breadcrumbs: false,
    };
    console.log(totalChild);
    console.log(NavigationItems[0].children);
    NavigationItems[0].children.push(samp);
    console.log(NavigationItems);*/
    return NavigationItems;
  }

  public getmenus(menus) {
     let childMenu = NavigationItems[0].children;
    let totalChild = childMenu.length;
    let _str = '';
    console.log(totalChild);
    console.log(NavigationItems[0].children);
    for(var i=0;i<menus.length;i++) {
      let str = menus[i];
      _str = str.toLowerCase();
      _str = _str.replace(' ','-');
      let samp = {
        id: str,
        title: menus[i],
        type: 'item',
        url: '/leftmenus/'+_str,
        classes: 'nav-item',
        icon: 'feather icon-monitor',
        breadcrumbs: false,
      };
      NavigationItems[0].children.push(samp);
    }
    // NavigationItems[0].children.push({
    //   id: 'demopage',
    //   title: 'Demo Page',
    //   type: 'item',
    //   url: '/demo-page',
    //   classes: 'nav-item',
    //   icon: 'feather icon-monitor',
    //   breadcrumbs: false,
    // })

    // NavigationItems[0].children.push({
    //   id: 'customerenquiry',
    //   title: 'Customer Enquiry',
    //   type: 'item',
    //   url: '/customer-enquiry',
    //   classes: 'nav-item',
    //   icon: 'feather icon-monitor',
    //   breadcrumbs: false,
    // })
    // NavigationItems[0].children.push({
    //   id: 'oldgoldpurchase',
    //   title: 'Old Gold Purchase',
    //   type: 'item',
    //   url: '/old-gold-purchase',
    //   classes: 'nav-item',
    //   icon: 'feather icon-monitor',
    //   breadcrumbs: false,
    // })
    // NavigationItems[0].children.push({
    //   id: 'purchasediractetail',
    //   title: 'Purchase Directetail',
    //   type: 'item',
    //   url: '/purchase-diractetail',
    //   classes: 'nav-item',
    //   icon: 'feather icon-monitor',
    //   breadcrumbs: false,
    // })
    // NavigationItems[0].children.push({
    //   id: 'purchasedirect',
    //   title: 'Purchase Direct',
    //   type: 'item',
    //   url: '/purchase-direct',
    //   classes: 'nav-item',
    //   icon: 'feather icon-monitor',
    //   breadcrumbs: false,
    // })
    console.log(NavigationItems);
    return NavigationItems;
  }

  public getBreadcrumb() {
    return NavigationItems;
  }
}
