import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pending-sales-order',
  templateUrl: './pending-sales-order.component.html',
  styleUrls: ['./pending-sales-order.component.scss']
})
export class PendingSalesOrderComponent implements OnInit {
  tasks:any[];
  columnhead:any[] = ['Order No','Order Date','Delivery Date','Amount','Customer Code','Customer Name','Mobile No','Fixed'];
    constructor(
      private activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
    }

    close() {
      this.activeModal.close();
    }

}
